import React, {useEffect, useState} from 'react';
import css from './add-project.module.less';
import classNames from 'classnames';
import moment from 'moment';
import {AddProjectSteps, AddressItem, PriceInput, UploadFile, UploadImage,} from "../../components";
import {Button, Checkbox, Form, Input, message, Modal, Select, Tabs} from 'antd';
import {AddProjectIndustry} from "../../components/add-project-industry";
import {useDataApi, useIsLogin, useUserInfo} from "../../hook";
import {ProModal} from "../../common";
import {SERVICE_PACTION} from "../../helps/helpsPaction";
import {useEffectOnce} from 'react-use';
import banner from 'aliasimgurl/banner_01.png'
import awaitImg from 'aliasimgurl/icon_await.png'
import successImg from 'aliasimgurl/icon_success.png'
import rejectImg from 'aliasimgurl/icon_reject.png'
import {ProView} from "../projestList/view";
import {FOREST_TYPE} from "../../common/static_number";
import {useHistory} from "react-router-dom";

const {TextArea} = Input;
const {Option} = Select;
const {TabPane} = Tabs;

const layout = {
    labelCol: {span: 8,},
    wrapperCol: {span: 10},
};

export const AddProject = (props) => {
    const params = props.location.state ;
    const [projectId,setProjectId] = useState('');
    const [step, setStep] = useState(0);
    const [initData, setInitData] = useState({});
    const [previewFlag, showPreview] = useState(false); //预览弹窗控制
    const [isLogin] = useIsLogin();
    const [userInfo] = useUserInfo();
    const [form] = Form.useForm();
    const {push} = useHistory();
    const [{data,setData,isLoading},updateParams] = useDataApi();
    useEffectOnce(() => {
        if(isLogin){
            Modal.warning({
                title:'温馨提示',
                content: '您还未登录，是否现在登录？',
                okText:'去登录',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    window.location.href = '/login'
                },
                onCancel:()=>{

                }
            });
        }


        scrollToTop();
    });
    function scrollToTop(argument) {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 2);
        };
    };
    useEffect(()=>{
        const {id = '',province_id} = params ||{};
        console.log("params",params);
        if(id){
            setProjectId(id);
            updateParams({url: '/api/forestryDetail', method: 'post', data: {id}})
        }else{
            if(province_id){
                params.area = [province_id];
            }
            setInitData({isLoading:true,...params})
        }
        scrollToTop();
    },[params]);
    useEffect(()=>{
        const {type=''} = params;
        if(!isLoading){
            const _data = data;
            const {province_id,city_id,county_id,industry_id,starttime,face_price,forestry_price} = _data;
            _data.isLoading = true;
            _data.area =  [province_id,city_id,county_id];
            _data.industry = industry_id;
            _data.price = {type:face_price,value:forestry_price };
           _data.starttime = moment(starttime) || null;
           if(type === 'detail'){
               //0=待审核，1=审核通过，2=审核未通过,3=完成
               if(_data.status === 0 || _data.status === 2){
                    setStep(1);
               }else if(_data.status === 1 ){
                   setStep(2);
               }
           }else{
               setStep(0);
           }
            setInitData({..._data});
        }
    },[isLoading]);
    const onsubmit = ({type}) => {
        if(!pactionFlag){
            return  Modal.warning({
                title:'温馨提示',
                content: '请先阅读《林权流转信息发布协议》',
                okText:'我知道了',
                maskClosable: true,
                onOk: ()=>{
                },
                onCancel:()=>{

                }
            });

        }

        const status_type = type ==='submit'? 2:1;
         form.validateFields().then((v)=>{
            const [province_id, city_id, county_id] = v.area;
            const {type,value} = v.price;
            ProModal.addPro({id:projectId,status_type,province_id, city_id,forestry_price:value, face_price:type, county_id,user_phone:userInfo.phone, ...v}).then(({data: {msg, code,data:id}}) => {
                if (code === 200) {
                    message.success("提交成功");
                    // push("detail");
                    setStep(Number.parseInt(step) + 1);
                    push({pathname:'/addproject',state:{id,type: 'detail'}});
                } else {
                    message.error(msg)
                }
            })
        })


    };

    const [serviceVisible, setServiceVisible] = useState(false);
    const [pactionFlag, setPactionFlag] = useState(false);

    const pactionChange = (e) => {
        setPactionFlag(e.target.checked);
    };

    return (
        <div className={classNames(css.box)}>
            <img className={css.banner} src={banner} alt=""/>

            <div className={'container'}>
                <AddProjectSteps current={step}/>
                <div className={css.content}>
                    {initData.isLoading &&
                        <Form form={form} className={css.form} initialValues={initData}  {...layout}>
                        <Tabs activeKey={step.toString()}>
                            <TabPane tab="填写基本信息" key="0">
                                <Form.Item label={'流转类别'} name={'forestry_type'} wrapperCol={14}
                                           rules={[{required: true, message: '请选择流转类别'}]}>
                                    <AddProjectIndustry size={'middle'}/>
                                </Form.Item>
                                <Form.Item label={'林地类型'} name={'type'}
                                           rules={[{required: true, message: '请填写林地类型'}]}>
                                    <Select placeholder={'请选择林地类型'} >
                                        {
                                            FOREST_TYPE.map(opt=>
                                                <Option  key={ opt.value } value={ opt.value }> { opt.name }</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label={'林地地区'} name={'area'}
                                           rules={[{required: true, message: '请选择林地地区',},({ getFieldValue }) => ({
                                               validator(rule, value) {
                                                   if(value && value[3]){
                                                       return Promise.reject('请选择林地地区');
                                                   }
                                                   return Promise.resolve();
                                               },
                                           })]}>
                                    <AddressItem size={'middle'}/>
                                </Form.Item>
                                <Form.Item label={'详细地址'} name={'address'} >
                                    <Input placeholder={'请填写详细地址'} />
                                </Form.Item>

                                <div className={css.row}>
                                    <Form.Item label={'林地面积'} name={'measure_area'} rules={[{required: true, message: '请填写林地面积'}]}>
                                        <Input placeholder={'林地面积'}/>
                                    </Form.Item>
                                    <div className={css.hint}>亩&nbsp;&nbsp;(1亩 ≈ 666.7平方米)</div>
                                </div>
                                <div className={css.row}>
                                    <Form.Item label={'预期总价'} name={'price'}
                                               rules={[{required: true, message: '请填写预期总价'}]}>
                                        <PriceInput />
                                    </Form.Item>
                                    <div className={css.hint}>万元</div>
                                </div>
                                <div className={css.row}>
                                    <Form.Item label={'使用年限'} name={'years'}  rules={[{required: true, message: '请填写使用年限'}]}>
                                        <Input placeholder={'使用年限'}  />
                                    </Form.Item>
                                    <div className={css.hint}>年</div>
                                </div>

                                <Form.Item label={'林地权属'} name={'ownership'}  rules={[{required: true, message: '请填写林地权属'}]}>
                                    <Select placeholder={'个人/集体/企业'} >
                                        <Option value={1}>个人</Option>
                                        <Option value={2}>集体</Option>
                                        <Option value={3}>企业</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item label={'权证情况'} name={'warrant'} rules={[{required: true, message: '请选择权证情况'}]}>
                                    <Select placeholder={'林权证/租赁合同/承包合同/流转合同'} >
                                        <Option value={1}>林权证</Option>
                                        <Option value={2}>租赁合同</Option>
                                        <Option value={3}>承包合同</Option>
                                        <Option value={4}>流转合同</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item label={'权证照片'} name={'image'} rules={[{required: true, message: '请上传权证照片'}]}>
                                    <UploadImage uploadNum={3} extra={'（权证照片不会对外展示，仅供平台审核，支持：JPG/JPEG/GIF/PNG 格式）'}/>
                                </Form.Item>
                                <Form.Item label={'林地照片'} name={'images'} rules={[{required: true, message: '请上传林地照片'}]}>
                                    <UploadImage uploadNum={3} extra={'（单张最大6M，最多上传6张，支持：JPG/JPEG/GIF/PNG 格式）'}/>
                                </Form.Item>
                                <Form.Item label={'林地视频'} name={'video'}>
                                    <UploadFile uploadNum={3} extra={'（视频大小：800M以内，支持：MP4/AVI/WMV 格式）'}/>
                                </Form.Item>
                                <Form.Item label={'信息标题'} name={'project_name'} rules={[{required: true, message: '请填写信息标题'}]}>
                                    <Input placeholder={'请填写信息标题'}/>
                                </Form.Item>
                                <Form.Item label={'林地详情'} name={'content'} wrapperCol={{span: 10}}
                                           rules={[{required: true, message: '请填写林地详情'}, {
                                               type: 'string',
                                               max: 1000,
                                               min: 20,
                                               message: '林地详情请控制在20-1000字之间'
                                           }]}>
                                    <TextArea autoSize type="text" placeholder={'请详细描述房屋情况、水电情况、交通情况、林木(树种)情况、开发建议及投资价值等（文字内容20-1000字）。'}/>
                                </Form.Item>
                                <Form.Item label={'联系人'} name={'user_name'}
                                           rules={[{required: true, message: '请填写联系人'}]}>
                                    <Input placeholder={'请填写联系人'}/>
                                </Form.Item>
                                <Form.Item label={'联系电话'}>
                                    <span style={{lineHeight: '32px'}}>{userInfo.phone}</span>

                                </Form.Item>
                                <Form.Item label={'备用电话'} name={'spare_phone'}>
                                    <Input placeholder={'请填写备用电话'}/>
                                </Form.Item>
                                <Form.Item className={css.pactionText} wrapperCol={{offset:8,span:10}}>
                                    <Checkbox onChange={pactionChange}>
                                        我已阅读并同意
                                    </Checkbox><span
                                    style={{cursor: "pointer", color: '#32CD9B'}}
                                    onClick={(e) => {setServiceVisible(true);
                                    }}>《林权流转信息发布协议》</span>
                                </Form.Item>
                                <div className={css.trigger}>
                                    {/*<Button type="primary" className={css.prePage} onClick={() => setStep(Number.parseInt(step) - 1)}>上一步</Button>*/}
                                    <div className={css.btn} onClick={()=>onsubmit({type:'submit'})}>立即发布</div>
                                </div>
                                <Form.Item label="" wrapperCol={{offset:6}} style={{color: '#999',fontSize: '13px'}}>
                                    <span style={{color: "#666"}}>温馨提示：</span><br/>
                                    1、发布页面中的手机号既作为您的注册账号，同时也作为您发布的土地的联系<br/>
                                    方式，您可以进入个人中心后进行修改；<br/>
                                    2、通过发布入口发布的信息，未经网站审核，暂不显示。您可前往会员中心查<br/>
                                    看项目信息审核状态；<br/>
                                    3、发布过程中，如遇任何问题，请致电客服，电话010 - 53673671。<br/>
                                </Form.Item>
                            </TabPane>
                            <TabPane tab="平台审核" key="1" className={css.result}>
                                {
                                    data.status === 0 &&  <div >
                                        <img src={awaitImg} alt="" className={css.status_img}/>
                                        <div className={css.title}>您已提交成功，进入平台审核阶段，请耐心等待！</div>
                                        <div className={css.subtitle}>如有其它任何疑问，请致电010 - 53673671</div>
                                        <div className={css.trigger}>
                                            <div className={css.btn} onClick={()=>push('/account/home')}>返回个人中心</div>
                                        </div>
                                    </div>
                                }

                                {
                                    data.status === 2 && <div >
                                        <img src={rejectImg} alt="" className={css.status_img}/>
                                        <div className={css.title}>抱歉，您提交的林地信息未通过平台审核，请修改后提交</div>
                                        <div className={css.remark}>
                                            <span className={css.label}>驳回原因:</span>
                                            <span >{data.close_reason}</span>
                                        </div>
                                        <div className={css.subtitle}>如有其它任何疑问，请致电010 - 53673671</div>

                                        <div className={css.trigger}>
                                            <div className={css.btn} onClick={()=>push({pathname:'/addproject',state:{id:params.id,type:'edit'}})} >去修改</div>
                                        </div>
                                    </div>
                                }
                            </TabPane>
                            <TabPane tab="正式发布" key="2"  className={css.result}>
                               <div>
                                    <img src={successImg} alt="" className={css.status_img}/>
                                    <div className={css.title}>恭喜，您发布的林地信息已经通过平台审核，请点击 <a href="/account/project/list">【我的发布】</a>查看！</div>
                                    <div className={css.subtitle}>您可以点击 <span>【专业评估】</span>对发布的林地进行打分评估，提高您的林地曝光程度，<br/> 如有其他任何疑问，请致电010 - 53673671！</div>
                                   <div className={css.trigger}>
                                       <div className={classNames(css.btn,css.backBtn)} onClick={()=>push({pathname:'/account/home'})} >返回个人中心</div>
                                       {!data.fraction && <div className={css.btn} onClick={()=>push({pathname:'/addAssess',state:{prodId:data.id}})} >专业评估</div>}
                                   </div>
                                </div>
                            </TabPane>
                        </Tabs>

                    </Form>
                    }
                </div>
            </div>
            <Modal visible={serviceVisible} closable={false} footer={null} width={'800px'}>
                <div style={{height: '600px', overflow: 'auto', marginTop: '20px'}}>
                     <div  dangerouslySetInnerHTML={{__html: SERVICE_PACTION}} />
                     <div style={{textAlign: 'center'}}>
                         <Button type={"primary"} size={"large"} onClick={() => setServiceVisible(false)} >我知道了</Button>
                     </div>
                </div>
            </Modal>
            <Modal visible={previewFlag}
                   width={850}

                   onCancel={()=>showPreview(false)}
                   footer={false}
            >
                <ProView match = {{params:{id:projectId,type:'preview'}}} />
            </Modal>
        </div>
    );
}
