import React, {useState} from 'react';
import css from './exchange-box.module.less';
import {useDataApi} from "../hook";
import { useHistory} from 'react-router-dom'
import banner01 from 'aliasimgurl/banner_01.png'
import classNames from 'classnames'
import {CardBox} from "./card-box";
import defaultImg from  'aliasimgurl/default_img.png';

export const ExchangeBox =(props)=>{
    const {style} = props;
    const [{ data,isLoading }] = useDataApi({ url: '/api/newsList', method: 'post', data: { pagesize:10,type: '4' ,page:1}, initialData: {} });
    const { push } = useHistory();
    const [active,setActive] = useState(0);

    const goDetail = (opt)=>{
        push({pathname:'/info/view/'+opt.id});
    };

    return (
        <CardBox title={'培训交流'}  style={{background:'#F5F5F5',...style}}>
            <div className={ css.card }>
                <div className={css.list}>
                    {
                        !isLoading && data.data.map((opt,index)=>
                            <div className={ classNames(css.item,active === index? css.active:'') } key={ index } onClick={()=>goDetail(opt)} onMouseOver={()=>setActive(index)}>
                                {/*<div className={css.index}>{index+1}</div>*/}
                                <img src={opt.images? opt.images : defaultImg} className={css.banner} alt=""/>
                                <div className={ css.name } >{ opt.title }</div>
                            </div>
                        )
                    }
                </div>
            </div>
        </CardBox>
    );
}