import React from 'react';
import classNames from 'classnames';
import css from './ForestList.module.less';
import {useHistory} from "react-router-dom";
import {getForestryType} from "../../../common/static_number";
import {EmptyData} from "../../../components/empty-data";

export const ForestList = (props)=>{
    const {data=[],handleCancelCollect} = props;
    const {push} = useHistory();
    const goDetail = (opt)=>{
        const {cid:id} = opt;
        push({pathname:'/exchange/view/'+id});
    };
    return (
        <div className={css.box}>
            <div className={css.list}>
                <div className={classNames(css.header)}>
                    <div className={css.name}>详情</div>
                    <div className={css.status} >发布时间</div>
                    <div className={css.operation}>操作</div>
                </div>
                { data.map((opt,index)=>
                    <div className={classNames(css.row)}>
                        <div className={css.name}  onClick={()=>goDetail(opt)}>
                            {opt.project_name|| '未命名'}
                        </div>
                        <div className={css.content}>
                            <div className={css.infoWrap}>
                                <p>预期总价：{opt.forestry_price === '0.00' ?  '面议':opt.forestry_price + '万'}</p>
                                <p style={{width: '300px'}}>林地地区：{opt.address}</p>
                                <p>使用年限：{ opt.years }年</p>
                                <p>林地面积：{ opt.measure_area }亩</p>
                                <p>
                                    林地权属：<span>{{1:'个人',2:'集体',3: '企业'}[opt.ownership]}</span>
                                </p>
                                <p>流转类别：{getForestryType(opt.forestry_type).name  }</p>
                                <p>
                                    证件情况：<span>{{1:'林权证',2:'租赁合同',3: '承包合同',4:'流转合同'}[opt.warrant]}</span>
                                </p>
                                <p>发起时间：{opt.createtime}</p>
                            </div>
                            <div className={css.status}>{opt.createtime}</div>
                            <div className={css.operation}>
                                <div className={css.btn} onClick={()=>handleCancelCollect(opt,index)}>取消收藏</div>
                            </div>
                        </div>
                    </div>

                )}
                {
                    !data.length && <EmptyData />
                }
            </div>
        </div>


    )
}
