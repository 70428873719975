import React from 'react';
import css from './index.module.less';
import {TitleBox} from "../components/titleBox";

/** 法律声明 */
export const Law = (props) => {
    return (
        <div className={css.body}>
            <TitleBox title={'法律声明'} />
            <div className={css.content}>
                <p className={css.header}>
                    若要访问和使用中国林业产业服务于交易平台（以下简称“本平台”），您必须不加修改地完全接受本协议中所包含的条款、条件和本平台即时刊登的通告，并且遵守有关互联网、万维网及/或本网站的相关法律、规定与规则。一旦您访问、使用了本平台网站，即表示您同意并接受了所有该等条款、条件及通告。
                </p>
                <div className={css.title}>一、本网站上的信息</div>
                <p>
                    1、本网站上关于中国林业产业服务于交易平台会员的注册信息均由会员自行提供，会员依法应对其提供的任何信息承担全部责任。本平台对任何使用或提供本网站信息的商业活动及其风险不承担任何责任。
                   <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                   2、浏览者可以下载本网站上显示的资料，但不得用于任何商业用途，无论是否在资料上明示，所有此等资料都是受到版权法的法律保护。浏览者没有获得中国林业产业服务于交易平台或各自的版权所有者明确的书面同意下，不得分发、修改、散布、再使用、再传递或使用本网站的内容用于任何公众商业用途。
                </p>

                <div className={css.title}>二、意见及网上论坛</div>
                <p>
                    本网站表述的任何意见均属于作者个人意见，并不代表中国林业产业服务于交易平台、中国林业产业服务于交易平台运营商或中林国控（北京）规划设计研究院有限公司的意见。
                </p>
                <div className={css.title}>三、版权和商标</div>
                <p>
                    中林国控（北京）规划设计研究院有限公司2001-2020版权所有。所有的权利均在全世界范围内受到法律保护，除非有其他的标注或在此等条款和规则中被允许使用，本网站上可阅读和可见的所有资料都受到知识产权法的保护。
                    "china-forest.com"和中国林业产业服务于交易平台LOGO商标或标识都是中林国控（北京）规划设计研究院有限公司的商品及服务商标和商号。所有其他中林国控（北京）规划设计研究院有限公司没有主张权利的商标和产品名称则可能是它们各自所有权人的商标或注册商标，未获得中国林业产业服务于交易平台或其他在本网站上有权使用商标的第三方的书面同意下，本网站不应理解为授权使用被展示于本网站的任何商标。
                </p>
                <div className={css.title}>四、免责声明</div>
                <p>中国林业产业服务于交易平台在此特别声明对如下事宜不承担任何法律责任：
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    1、中国林业产业服务于交易平台在此声明，对您使用网站、与本网站相关的任何内容、服务或其它链接至本网站的站点、内容均不作直接、间接、法定、约定的保证。
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    2、无论在任何原因下（包括但不限于疏忽原因），对您或任何人通过使用本网站上的信息或由本网站链接的信息，或其他与本网站链接的网站信息所导致的损失或损害（包括直接、间接、特别或后果性的损失或损害，例如收入或利润之损失，电脑系统之损坏或数据丢失等后果），责任均由使用者自行承担（包括但不限于疏忽责任）使用者对本网站的使用即表明同意承担浏览本网站的全部风险，中国林业产业服务于交易平台、中国林业产业服务于交易平台运营商和中国林业产业服务于交易平台关联公司对使用者在本网站存取资料所导致的任何直接、相关的、后果性的、间接的或金钱上的损失不承担任何责任。

                </p>
                <p>

                </p>

            </div>
        </div>
    )
}