import React from 'react';
import css from './info-box.module.less';
import {BidList,LeftBox,EliteInfoList} from "./index";
import banner02 from 'aliasimgurl/banner_02.png'
export const InfoBox =(props)=>{
    const {data={},goDetail} = props;
    return (
        <div className={css.box}>
            <div className={css.left}>
                <div className={css.infoBox} >
                    {
                        data.news1.map(opt=> <div className={css.infoItem} key={opt.id} onClick={()=>goDetail(opt)}>
                                {opt.title}
                        </div>)
                    }
                </div>
                <div className={css.infoBottom}>
                    {data.news6.map(opt=>
                        <div className={css.largeInfo} key={opt.id} onClick={()=>goDetail(opt)}>
                            <img src={opt.images}  alt="" className={css.banner} />
                            <div className={css.largeTitle}>{opt.title}</div>
                        </div>
                       ) }
                    <div className={css.info}>
                        {data.news2.map(opt=>
                        <div key={opt.id} onClick={()=>goDetail(opt)}>
                            {opt.title}
                        </div>)}
                    </div>
                </div>
            </div>
            <div className={css.right} >
                <EliteInfoList items={ data.newslist} goDetail={goDetail}/>
            </div>
        </div>
    );

}