import React, {useEffect,Fragment} from 'react';
import {useEffectOnce} from 'react-use';
import {Breadcrumb, HotInfoList,} from '../../components';
import {message} from 'antd';
import {useDataApi} from '../../hook';
import css from './index.module.less';
import {ProModal} from "../../common";
import {tap} from "rxjs/operators";
import banner02 from 'aliasimgurl/banner_02.png';
import {createFromIconfontCN} from '@ant-design/icons';
import {useHistory} from 'react-router-dom'
import {PLAN_TYPE} from "../../common/static_number";


const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_l6tmts73qde.js',
});
export const DesignView = (props) => {

    const {id=''} = props.match.params? props.match.params :{};
    const [{data,isLoading,setData},updateParams] = useDataApi();
    const {push} = useHistory();
    useEffectOnce(() => {
        function scrollToTop(argument) {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 8);
            };
        };
        scrollToTop();
    });
    useEffect(()=>{
        updateParams({url: '/api/plandetail', method: 'post', data: {id}})
    },[id]);

    /** 收藏 */
    const handleCollect = ()=>{
        ProModal.collect({id,type:9}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('收藏成功',3);
                const _data = data;
                _data.is_collect = true;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    /** 取消收藏 */
    const handleCancelCollect = ()=>{
        ProModal.cancelCollect({id,type:9}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('取消成功',3);
                const _data = data;
                _data.is_collect = false;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };

    return (
        <div >
            {/*<YcImage imgUrl={ headerImg } />*/}
            {!isLoading && <Fragment>
                <div className="container">
                    <Breadcrumb list={ [{name:'规划设计',path:'/design'},{name: PLAN_TYPE[data.plan_type] ||""}] } />
                </div>
                <div style={{background: '#fff'}}>
                    <div className="container">
                        <div className={css.bodyWarp}>
                            <div className={css.leftWarp}>
                                <div className={ css.viewWrap }>
                                    <div className={ css.titleInfo }>
                                        <div className={ css.viewTitle }>{data.title}</div>
                                        <p>
                                            <span className={css.text}>{data.createtime}</span>
                                            <span className={css.text}>点击量：{data.read_num}</span>
                                            {
                                                data.is_collect?  <span className={css.check}  onClick={handleCancelCollect} style={{cursor: "pointer"}}>
                                            <IconFont type={'icon-collection-b'}/>
                                            <span className={css.text}>已收藏</span>
                                        </span>:<span onClick={handleCollect} style={{cursor: "pointer"}}>
                                           <IconFont  type={'icon-shoucang1'}/><span className={css.text} >收藏</span>
                                        </span>
                                            }
                                        </p>
                                    </div>
                                    <div className={css.desInfo} dangerouslySetInnerHTML={{__html: data.content}}/>
                                </div>
                            </div>
                            <div className={css.rightWarp}>
                                <HotInfoList style={{marginTop: '30px'}}/>
                                <div className={css.bannerWarp} onClick={()=>push({pathname:'/addproject',state:{}})}>
                                    <img src={banner02} alt=""/>
                                </div>
                                {/*<HotCityList style={{marginTop: '20px'}}/>*/}

                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
            }
        </div>


    )
}