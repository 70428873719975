import React from 'react';
import classNames from 'classnames';
import css from './new-project-list.module.less';
import {useHistory} from "react-router-dom";
import {CaretDownOutlined} from '@ant-design/icons';
import {useDataApi} from "../../../hook";

export const NewProjectList =(props)=>{

    const {items=[]} = props;
    const {push} = useHistory();
    const [{ ...areaData }] = useDataApi({url:'/api/getCity',method: 'post',data:{pid:0,type:2},initialData: {}});
    const goDetail = (opt)=>{
        push({pathname:'/exchange',state:{province_id:opt.id}})
    }
    return (
        <div className={css.list} >
            <div className={classNames(css.territory)}>
                 地域 <CaretDownOutlined style={{color: '#1D8526'}}/>
                 <div className={css.ext}>
                     <div className={css.extBox}>
                         {
                             !areaData.isLoading && areaData.data.map(item=> <span key={item.name} onClick={()=>goDetail(item)}>{item.name}</span>)
                         }
                     </div>
                 </div>
            </div>
            {
                items.map((opt, index) =>
                    <div className={css.item} key={index} onClick={()=>push({pathname: '/exchange/view/'+opt.id})}>
                        <div className={css.area}>{opt.pname}&nbsp;&nbsp;|&nbsp;&nbsp;</div>
                        <div className={css.title}>{opt.project_name}</div>
                    </div>
                )
            }
        </div>
    );

}