import React from 'react';
import css from './index.module.less';
import {useDataApi} from "../../../hook";
import {TitleBox} from "../components/titleBox";

/** 关于我们 */
export const Home = (props) => {
    return (
        <div className={css.body}>
            <TitleBox title={'平台介绍'} />
            <div className={css.content}>
               <div>
                   <p>
                       中国林业产业服务与交易平台(<a href="http://www.china-forest.com">http://www.china-forest.com</a>)是中国权威的林业相关行业门户网站，为林业行业的参与者、关注者提供全面、深入、专业的行业资讯及行情数据等，并倾心打造理性、包容、思辨、开放的行业交流服务社区，为林权所有者及行业从业人员制定投资决策提供客观真实的参考信息。
                   </p>
                   <p>
                       贯彻执行和推广国家关于林业改革的精神，坚持“公开、公正、公平”的准则，确保林地、林木价值得到合理体现；致力于打造一流的林权交易服务、林业产业评估服务、林业产业规划设计、林业电子招投标、林业百大项目库等平台，建立辐射全国，统一、规范、活跃的林权交易市场。
                       吸引社会资本流向林业产业，助力全国林业产权制度改革，推动林业经济发展。
                   </p>

               </div>
            </div>

        </div>
    )
}