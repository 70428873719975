import React from 'react';
import classNames from 'classnames';
import css from './list.module.less';
import banner01 from 'aliasimgurl/banner_01.png'
import {EmptyData} from "../../../components/empty-data";
/** 项目资讯 */
export const List =(props)=>{

    const {data,count,toView} = props;

    return (
        <div>
            <div className={css.list} style={{minHeight: '350px'}}>
            {
             data.length > 0 && data.map((opt, index) =>
                    <div className={css.item} key={index} onClick={() => toView(opt)}>
                        <div className={css.leftWarp}>
                            <img src={opt.images} className={css.img} alt=""/>
                        </div>
                        <div className={css.rightWarp}>
                            <div className={css.title}>{opt.title}</div>
                            <div className={ css.time }>{ opt.createtime }</div>
                            <div className={css.tags}>
                                {opt.keyword && opt.keyword.map((opt)=><span key={opt}>{opt}</span>)}
                            </div>
                        </div>


                    </div>
                )
            }
            {
                count != 0 || <EmptyData />
            }
        </div>
        </div>
    );

}