import React, {useEffect, useState,Fragment} from 'react';
import css from './add-assess.module.less';
import classNames from 'classnames';
import {AddAssessSteps, AddressItem,} from "../../components";
import {Button, Form, Input, message, Modal, Select, Tabs} from 'antd';
import {useDataApi, useIsLogin} from "../../hook";
import {ProModal, ServicesModel} from "../../common";
import {SERVICE_PACTION} from "../../helps/helpsPaction";
import {useEffectOnce} from 'react-use';
import {FOREST_TYPE, FORESTRY_TYPE} from "../../common/static_number";
import {useHistory} from "react-router-dom";
import {Score} from "./components/score";
import {CheckCircleOutlined} from '@ant-design/icons';

const {Option} = Select;
const {TabPane} = Tabs;

const layout = {
    labelCol: {span: 8,},
    wrapperCol: {span: 10},
};
/** 林权评估 */
export const AddAssess = (props) => {
    const {id} = props.match.params ? props.match.params : {};
    const params = props.location.state ? props.location.state:{} ;
    const [step, setStep] = useState(0);
    const [isLogin] = useIsLogin();
    const [form] = Form.useForm();
    const {push} = useHistory();
    const [initData, setInitData] = useState({});

    const [{data,isLoading},updateParams] = useDataApi();
    useEffectOnce(() => {
        if(isLogin){
            Modal.warning({
                title:'温馨提示',
                content: '您还未登录，是否现在登录？',
                okText:'去登录',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    window.location.href = '/login'
                },
                onCancel:()=>{

                }
            });
        }
        scrollToTop();
    });
    function scrollToTop(argument) {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 2);
        };
    };
    useEffect(()=>{
        const {province_id,prodId} = params;
        if(id){
            console.log("props.match.params",props);
            updateParams({url: '/api/getReport', method: 'post', data: {id}});
            setStep(5)
        }
        scrollToTop();
        if(!prodId){

            if(province_id){
                params.region = [province_id];
            }
            setInitData({isLoading:true,...params})
        }else{
            console.log("props.match.params",props);
            updateParams({url: '/api/forestryDetail', method: 'post', data: {id:prodId}});
        }



    },[id]);
    useEffect(()=>{
        const {prodId} = params;
        if(!isLoading && prodId){
           console.log(data);
            const {province_id, city_id, county_id,measure_area} = data;

            setInitData({isLoading:true,area:measure_area,region:[province_id,city_id,county_id],...data})
        }

    },[isLoading]);


    const onSubmit = () => {
        const {id} = params;
         form.validateFields().then((v)=>{
            const [province_id, city_id, county_id] = v.region;
            ProModal.assessment({province_id, city_id, county_id,forestry_id:id, ...v}).then(({data: {msg, code,data}}) => {
                if (code === 200) {
                    message.success("提交成功");
                    push({pathname:'/addAssess/'+data.id});
                } else {
                    message.error(msg)
                }
            })
        }).catch((e)=>{
            console.log("=====",e);
         })
    };

    const [serviceVisible, setServiceVisible] = useState(false);

    const handlePreStep = (e) => {
        setStep(Number.parseInt(step) - 1);
    };
    const handleNextStep = (e) => {

        console.log("form.validateFields(['nickname']);",step);
        switch(step+''){
            case '0':
                form.validateFields(['region','years','area','type','forestry_type','huinong','policy']).then((v)=>{
                    setStep(Number.parseInt(step) + 1);
                }).catch(e=>{
                    return
                })
                break;
            case '1':
                form.validateFields(['soil','thickness','land_fertility','shape','land_leveling','irrigation','drainage']).then((v)=>{
                    setStep(Number.parseInt(step) + 1);
                }).catch(e=>{
                    return
                })
                break;
            case '2':
                form.validateFields(['water_supply','power_supply','network','road','mechanization','subsidiary']).then((v)=>{
                    setStep(Number.parseInt(step) + 1);
                }).catch(e=>{
                    return
                })
                break;
            case '3':
                form.validateFields(['related','airport','high_speed_rail','expressway','railway','national_highway','port']).then((v)=>{
                    setStep(Number.parseInt(step) + 1);
                }).catch(e=>{
                    return
                })
                break;

        }
    };
    const addProposal = ()=>{
        ServicesModel.addProposal({type:7,f_id:id}).subscribe({
            next:res=>{
                Modal.success({
                    title: '您提交的专家评估信息已发送成功，请等待工作人员与您联系！',
                    content: `如有其它疑问请致电 010 - 53673671`,
                });
            }
        })
    }

    return (
        <div className={classNames(css.box)}>
            <div className={'container'}>
                <AddAssessSteps current={step}/>
                <div className={css.content}>
                    {
                        initData.isLoading &&  <Form form={form} className={css.form} initialValues={initData} {...layout}>
                            <Tabs activeKey={step.toString()}>
                                <TabPane tab="林地基本信息" key="0">
                                    <Form.Item label={'所属地区'} name={'region'}
                                               rules={[{required: true, message: '请选择所属地区',},({ getFieldValue }) => ({
                                                   validator(rule, value) {
                                                        // console.log(value,value[3])
                                                       if(value.length<3){
                                                           return Promise.reject('请选择所属地区');
                                                       }
                                                       return Promise.resolve();
                                                   },
                                               })]}>
                                        <AddressItem size={'middle'}/>
                                    </Form.Item>
                                    <div className={css.row}>
                                        <Form.Item label={'使用年限'} name={'years'} rules={[{required: true, message: '请输入使用年限'}]}>
                                            <Input placeholder={'请输入使用年限'}/>
                                        </Form.Item>
                                        <div className={css.hint}>年</div>
                                    </div>
                                    <div className={css.row}>
                                        <Form.Item label={'林地面积'} name={'area'} rules={[{required: true, message: '请选择林地面积'}]}>
                                            <Input placeholder={'林地面积'}/>
                                        </Form.Item>
                                        <div className={css.hint}>亩&nbsp;&nbsp;(1亩 ≈ 666.7平方米)</div>
                                    </div>
                                    <Form.Item label={'林地类型'} name={'type'}
                                               rules={[{required: true, message: '请选择林地类型'}]}>
                                        <Select placeholder={'请选择林地类型'} >
                                            {
                                                FOREST_TYPE.map(opt=>
                                                    <Option  key={ opt.value } value={ opt.value }> { opt.name }</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'流转类别'} name={'forestry_type'} rules={[{required: true, message: '请选择流转类别'}]}>
                                        <Select placeholder={'请选择林地类型'} >
                                            {FORESTRY_TYPE.map((item,index)=>{
                                                return  <Option value={item.value} >{item.name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>


                                    <Form.Item label={'惠农政策'} name={'huinong'} rules={[{required: true, message: '请选择惠农政策'}]}>
                                        <Select placeholder={'请选择惠农政策'} >
                                            <Option value={1}>种植补贴</Option>
                                            <Option value={2}>良种补贴</Option>
                                            <Option value={3}>农资补贴</Option>
                                            <Option value={4}>农机购置补贴</Option>
                                            <Option value={5}>农机报废补贴</Option>
                                            <Option value={6}>土壤有机质提升补贴</Option>
                                            <Option value={7}>畜牧良种补贴</Option>
                                            <Option value={8}>动物防疫补贴</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'支持政策'} name={'policy'} rules={[{required: true, message: '请选择支持政策'}]}>
                                        <Select placeholder={'请选择支持政策'} >
                                            <Option value={1}>贷款补息</Option>
                                            <Option value={2}>贷款利息优惠</Option>
                                            <Option value={3}>税收优惠</Option>
                                            <Option value={4}>最低收购价政策</Option>
                                            <Option value={5}>畜牧养殖扶植政策</Option>
                                        </Select>
                                    </Form.Item>
                                    <div className={css.trigger}>
                                        <div className={css.btn} onClick={handleNextStep}>下一步</div>
                                    </div>
                                </TabPane>
                                <TabPane tab="填写地块信息" key="1" >
                                    <Form.Item label={'土壤质地'} name={'soil'}
                                               rules={[{required: true, message: '请选择土壤质地'}]}>
                                        <Select placeholder={'请选择土壤质地'} >
                                            <Option value={1}>壤土</Option>
                                            <Option value={2}>粘土</Option>
                                            <Option value={3}>砂土</Option>
                                            <Option value={4}>砂砾土</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'有效土层厚度'} name={'thickness'}
                                               rules={[{required: true, message: '请选择有效土层厚度'}]}>
                                        <Select placeholder={'请选择有效土层厚度'} >
                                            <Option value={1}>大于150cm</Option>
                                            <Option value={2}>100-150cm</Option>
                                            <Option value={3}>60-100cm</Option>
                                            <Option value={4}>30-60cm</Option>
                                            <Option value={5}>小于30cm</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'土地肥力'} name={'land_fertility'} rules={[{required: true, message: '请选择土地肥力'}]}>
                                        <Select placeholder={'请选择土地肥力'} >
                                            <Option value={1}>极高</Option>
                                            <Option value={2}>较高</Option>
                                            <Option value={3}>一般</Option>
                                            <Option value={4}>较低</Option>
                                            <Option value={5}>极低</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'地块形状'} name={'shape'} rules={[{required: true, message: '请选择地块形状'}]}>
                                        <Select placeholder={'请选择地块形状'} >
                                            <Option value={1}>规则</Option>
                                            <Option value={2}>一般</Option>
                                            <Option value={3}>不规则</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item label={'平整程度'} name={'land_leveling'} rules={[{required: true, message: '请选择平整程度'}]}>
                                        <Select placeholder={'请选择平整程度'} >
                                            <Option value={1}>非常平整</Option>
                                            <Option value={2}>平整</Option>
                                            <Option value={3}>一般</Option>
                                            <Option value={4}>不平整</Option>
                                            <Option value={5}>非常不平整</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'灌溉条件'} name={'irrigation'} rules={[{required: true, message: '请选择灌溉条件'}]}>
                                        <Select placeholder={'请选择灌溉条件'} >
                                            <Option value={1}>充分满足</Option>
                                            <Option value={2}>基本满足</Option>
                                            <Option value={3}>一般满足</Option>
                                            <Option value={4}>无灌溉条件</Option>
                                        </Select>
                                    </Form.Item>
                                    <div className={css.row}>
                                        <Form.Item label={'排水条件'} name={'drainage'} rules={[{required: true, message: '请选择排水条件'}]}>
                                            <Select placeholder={'请选择排水条件'} >
                                                <Option value={1}>充分满足</Option>
                                                <Option value={2}>基本满足</Option>
                                                <Option value={3}>一般满足</Option>
                                                <Option value={4}>无排水系统</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className={css.trigger}>
                                        <div className={classNames(css.btn,css.backBtn)} onClick={handlePreStep} >上一步</div>
                                        <div className={css.btn} onClick={handleNextStep}>下一步</div>
                                    </div>
                                </TabPane>
                                <TabPane tab="填写配套设施" key="2" >
                                    <Form.Item label={'供水情况'} name={'water_supply'}
                                               rules={[{required: true, message: '请选择供水情况'}]}>
                                        <Select placeholder={'请选择供水情况'} >
                                            <Option value={1}>已接通</Option>
                                            <Option value={2}>可接通</Option>
                                            <Option value={3}>难接通</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'供电情况'} name={'power_supply'}
                                               rules={[{required: true, message: '请选择供电情况'}]}>
                                        <Select placeholder={'请选择供电情况'} >
                                            <Option value={1}>已接通</Option>
                                            <Option value={2}>可接通</Option>
                                            <Option value={3}>难接通</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'网络情况'} name={'network'} rules={[{required: true, message: '请选择网络情况'}]}>
                                        <Select placeholder={'请选择网络情况'} >
                                            <Option value={1}>已接通</Option>
                                            <Option value={2}>可接通</Option>
                                            <Option value={3}>未接通</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'道路情况'} name={'road'} rules={[{required: true, message: '请选择进入道路'}]}>
                                        <Select placeholder={'请选择进入道路'} >
                                            <Option value={1}>水泥路</Option>
                                            <Option value={2}>土路</Option>
                                            <Option value={3}>无</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'机械化程度'} name={'mechanization'} rules={[{required: true, message: '请选择机械化程度'}]}>
                                        <Select placeholder={'请选择机械化程度'} >
                                            <Option value={1}>基本机械化</Option>
                                            <Option value={2}>一般机械化</Option>
                                            <Option value={3}>无</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'附属设施'} name={'subsidiary'}
                                               rules={[{required: true, message: '请选择附属设施'}]}>
                                        <Select placeholder={'请选择附属设施'} mode={'multiple'}>
                                            <Option value={0}>无</Option>
                                            <Option value={1}>农机房</Option>
                                            <Option value={2}>生活用房</Option>
                                            <Option value={3}>水井</Option>
                                            <Option value={4}>畜禽舍</Option>
                                            <Option value={5}>晾晒场</Option>
                                        </Select>
                                    </Form.Item>
                                    <div className={css.trigger}>
                                        <div className={classNames(css.btn,css.backBtn)} onClick={handlePreStep} >上一步</div>
                                        <div className={css.btn} onClick={handleNextStep}>下一步</div>
                                    </div>
                                </TabPane>
                                <TabPane tab="填写周边环境" key="3" >
                                    <Form.Item label={'相关配套'} name={'related'}
                                               rules={[{required: true, message: '请选择相关配套'}]}>
                                        <Select placeholder={'请选择相关配套'} mode={'multiple'} >
                                            <Option value={1}>农科站</Option>
                                            <Option value={2}>农副产品批发市场</Option>
                                            <Option value={3}>冷藏保鲜</Option>
                                            <Option value={4}>仓储</Option>
                                            <Option value={5}>物流</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'机场距离'} name={'airport'} rules={[{required: true, message: '请选择机场距离'}]}>
                                        <Select placeholder={'请选择机场距离'} >
                                            <Option value={1}>0-10km</Option>
                                            <Option value={2}>10-50km</Option>
                                            <Option value={3}>50-100km</Option>
                                            <Option value={4}>100km以上</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'高铁距离'} name={'high_speed_rail'} rules={[{required: true, message: '请选择高铁距离'}]}>
                                        <Select placeholder={'请选择高铁距离'} >
                                            <Option value={1}>0-10km</Option>
                                            <Option value={2}>10-50km</Option>
                                            <Option value={3}>50-100km</Option>
                                            <Option value={4}>100km以上</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'高速距离'} name={'expressway'} rules={[{required: true, message: '请选择高速距离'}]}>
                                        <Select placeholder={'请选择高速距离'} >
                                            <Option value={1}>0-10km</Option>
                                            <Option value={2}>10-50km</Option>
                                            <Option value={3}>50-100km</Option>
                                            <Option value={4}>100km以上</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'铁路距离'} name={'railway'} rules={[{required: true, message: '请选择铁路距离'}]}>
                                        <Select placeholder={'请选择铁路距离'} >
                                            <Option value={1}>0-10km</Option>
                                            <Option value={2}>10-50km</Option>
                                            <Option value={3}>50-100km</Option>
                                            <Option value={4}>100km以上</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'国道距离'} name={'national_highway'} rules={[{required: true, message: '请选择国道距离'}]}>
                                        <Select placeholder={'请选择国道距离'} >
                                            <Option value={1}>0-10km</Option>
                                            <Option value={2}>10-50km</Option>
                                            <Option value={3}>50-100km</Option>
                                            <Option value={4}>100km以上</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'港口距离'} name={'port'} rules={[{required: true, message: '请选择港口距离'}]}>
                                        <Select placeholder={'请选择港口距离'} >
                                            <Option value={1}>距离0-10km</Option>
                                            <Option value={2}>距离10-50km</Option>
                                            <Option value={3}>距离50-100km</Option>
                                            <Option value={4}>距离100km以上</Option>
                                        </Select>
                                    </Form.Item>
                                    <div className={css.trigger}>
                                        <div className={classNames(css.btn,css.backBtn)} onClick={handlePreStep} >上一步</div>
                                        <div className={css.btn} onClick={handleNextStep}>下一步</div>
                                    </div>
                                </TabPane>
                                <TabPane tab="填写经验现状" key="4" >
                                    <Form.Item label={'地块利用现状'} name={'utilization'}
                                               rules={[{required: true, message: '请选择地块利用现状'}]}>
                                        <Select placeholder={'请选择地块利用现状'} >
                                            <Option value={1}>种植</Option>
                                            <Option value={2}>养殖</Option>
                                            <Option value={3}>无</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'地块产出'} name={'land_output'}
                                               rules={[{required: true, message: '请选择地块产出'}]}>
                                        <Select placeholder={'请选择地块产出'} >
                                            <Option value={1}>林木</Option>
                                            <Option value={2}>果树</Option>
                                            <Option value={3}>羊</Option>
                                            <Option value={4}>禽类</Option>
                                            <Option value={5}>无</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'适合何种经营'} name={'fit'} rules={[{required: true, message: '请选择适合何种经营'}]}>
                                        <Select placeholder={'请选择适合何种经营'} >
                                            <Option value={1}>种植作物</Option>
                                            <Option value={2}>种植树木</Option>
                                            <Option value={3}>养殖</Option>
                                            <Option value={4}>休闲农业</Option>
                                            <Option value={5}>无</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={'主要收入来源'} name={'income'} rules={[{required: true, message: '请选择主要收入来源'}]}>
                                        <Select placeholder={'请选择主要收入来源'} >
                                            <Option value={1}>在家种养</Option>
                                            <Option value={2}>外出务工</Option>
                                            <Option value={3}>无</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item label={'劳动力资源情况'} name={'labor_force'} rules={[{required: true, message: '请选择劳动力资源情况'}]}>
                                        <Select placeholder={'请选择劳动力资源情况'} >
                                            <Option value={1}>充足</Option>
                                            <Option value={2}>一般</Option>
                                            <Option value={3}>匮乏</Option>
                                        </Select>
                                    </Form.Item>
                                    <div className={css.trigger}>
                                        <div className={classNames(css.btn,css.backBtn)} onClick={handlePreStep} >上一步</div>
                                        <div className={css.btn} onClick={onSubmit}>开始评估</div>
                                    </div>
                                </TabPane>
                                <TabPane tab="生成评估报告" key="5" className={css.result}>
                                    {!isLoading && <Fragment>
                                        <div className={css.header}><CheckCircleOutlined /> 您提交的林地信息已评估成功！</div>
                                        <div className={css.title}>林地综合评估得分：<span>{data.comprehensive}</span></div>

                                        <Score data={data}/>
                                        <div className={css.footer}>
                                            该林地由平台行业大数据系统进行测评，评估受自然因素影响较大，该评估分数仅供参考，如需进一步准确评估方案及报告， 请点击<span>【专家评估】进行实地评估</span>
                                            ，如有其他任何疑问，请致电010 - 53673671！
                                        </div>
                                        <div className={css.trigger}>
                                            <div className={css.btn} onClick={addProposal}>专家评估</div>
                                        </div>
                                    </Fragment>
                                    }
                                </TabPane>
                            </Tabs>

                        </Form>
                    }

                </div>
            </div>
            <Modal visible={serviceVisible} closable={false} footer={null} width={'800px'}>
                <div style={{height: '600px', overflow: 'auto', marginTop: '20px'}}>
                     <div  dangerouslySetInnerHTML={{__html: SERVICE_PACTION}} />
                     <div style={{textAlign: 'center'}}>
                         <Button type={"primary"} size={"large"} onClick={() => setServiceVisible(false)} >我知道了</Button>
                     </div>
                </div>
            </Modal>
        </div>
    );
}