import React from 'react';
import css from './design-box.module.less';
import {NewItem} from "./index";
import banner_02 from 'aliasimgurl/banner_02.png';
import {Col, Row} from 'antd'
import {resourcesUrl} from "../../../common/static_number";

export const DesignBox =(props)=>{
    const {data={},goDetail} = props;
    return (
        <div className={css.box}>
            { data.plan1.map((opt)=>
                <div className={css.largeInfo} key={opt.id} onClick={()=>goDetail(opt)}>
                    <img src={ resourcesUrl + opt.images[0]}  alt="" className={css.banner}  />
                    <div className={css.largeTitle}>{opt.title}</div>
                </div>
                ) }
            {
                data.plan2.map((opt)=><NewItem item={opt} key={opt.id} goDetail={goDetail}/>)
            }
        </div>
    );

}