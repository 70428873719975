import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {Breadcrumb, YcPagination, YcRadio,HotInfoList} from '../../components';
import {List} from './components';
import {useDataApi} from '../../hook/dataApi';
import css from './index.module.less';
import banner01 from 'aliasimgurl/obj_banner_01.png';

export const ProList = (props) => {
    let { push,location:{ pathname }  } = useHistory();

    const [searchValue, setSearch] = useState({});

    const [path, setPath] = useState({name:'最新项目库'});

    useEffect(()=>{
        setSearch({...searchValue,...props.location.state});
    },[props.location.state]);

    function updateValue(value) {
        let _v = { ...searchValue, ...value };
        setSearch(_v);
    }

    const [{ data },updateHeader] = useDataApi({initialData: []});
    const [searchLable, setSearchLabel] = useState([]);
    const [ codes,setCodes ] = useState([]);

    useEffect(() => {
        function updateLabel(argument) {
            let _res = data.map((opt, index) => {
                const list =   opt.son.map((o, i) => ({
                    name: o.name,
                    value: o.id
                }));
                return {
                    name: opt.name,
                    code: codes[index],
                    defaultValue: searchValue[codes[index]] || 0,
                    items: [{
                        name: '全部',
                        value: 0
                    },...list]
                }
            });
            setSearchLabel(_res);
        };
        updateLabel();
    }, [data,codes,searchValue]);

    const [ { isLoading,...listData },updateParams ]  =useDataApi({});

    useEffect(() => {

        updateParams({url:'/pro/projectlist',method:'post',data:{type:1,page:1,pagesize:8, ...searchValue}});

    }, [searchValue]);

    useEffect(() => {
         let c_id = '';
         let defaultValue = {type:0,'industry_id':0, 'money':0, 'time':0,'stage_id':0,'province_id':0};

         switch (pathname) {
             case '/obj/exchequer' :
                 c_id = 387;
                 defaultValue.type = 1;
                 setCodes(['industry_id', 'money', 'time', 'stage_id','province_id']);
                 setPath({name:'财政部项目库'});
                 break;
             case '/obj/NDRC' :
                 c_id = 387;
                 defaultValue.type = 2;
                 setCodes(['industry_id', 'money', 'time', 'stage_id','province_id']);
                 setPath({name:'发改委项目库'});
                 break;
             case '/obj/local' :
                 c_id = 387;
                 defaultValue.type = 3;
                 setCodes(['industry_id', 'money', 'time', 'stage_id','province_id']);
                 setPath({name:'地方项目库'});
                 break;
             default:
                 c_id = 407;
                 setCodes(['industry_id', 'money', 'time', 'type','province_id'])
                 setPath({name:'最新项目库'});
                 break;
         }
         updateValue(...defaultValue);
         updateHeader({url:'/api/marketHeader',method:'post',data:{ c_id, type: 1 },initialData: []});
    }, [pathname]);

    function toView(opt) {
        const {id} = opt;
        push({pathname:'/obj/view/'+id})
    }

    function pageChange (v){
        updateValue({page:v})
    }

    return (
        <div className="container">
            <div className={css.bodyWarp}>
                <div className={css.leftWarp}>
                    <Breadcrumb list={ {name:path.name} } />
                    <div className={ css.searchWrap }>
                        {
                            searchLable.map((opt,index)=>
                                <YcRadio
                                    key={ index }
                                    items={ opt.items }
                                    className={ css.searchItem }
                                    label={ opt.name }
                                    name={ opt.code }
                                    value={ opt.defaultValue }
                                    more
                                    onChange={ updateValue } />
                            )
                        }
                    </div>
                    <div className={ css.listWrap }>
                        <List itemsLength={ 16 } data={ listData.data.data || [] } count={listData.data.count} toView={ toView } />
                        <YcPagination
                            total={ listData.data.count || 0 }
                            current={ searchValue.page}
                            pageSize={8}
                            onChange={pageChange}
                            style={ {margin:'30px 0'} } />
                    </div>
                </div>
                <div className={css.rightWarp}>
                    <div className={css.bannerWarp} onClick={()=>push({pathname: '/addproject',state:{}})}>
                        <img src={banner01} alt="" />
                    </div>
                    <HotInfoList style={{marginTop: '30px'}}/>
                </div>
            </div>

        </div>
    );
}
