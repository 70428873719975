import {store} from '../helps';
import {useEffect, useState} from 'react';
import cookie from 'react-cookies'

export const useIsLogin = ()=>{

    const [isLogin,setIsLogin] = useState(false);
    useEffect(()=>{
        let _isLogin = cookie.load('token') ||'';
        setIsLogin(!!_isLogin);
        console.log("isLogin",!!_isLogin);
    },[isLogin]);

    return [isLogin,setIsLogin];
}