import React from 'react';
import {Route, Switch} from "react-router-dom";
import css from './index.module.less';
import {SubNav} from "./components";
import {Home} from "./home";
import {Adve} from "./adve";
import {Law} from "./law";
import {Contact} from "./contact";

/** 关于我们 */
export const About = (props) => {

    return (
        <div style={{background: '#F5F5F3'}}>
            <div className="container">
                <div className={css.about}>
                    <SubNav />
                    <Switch>
                        <Route exact from="/about/home" component={Home} />
                        <Route exact path="/about/contact" component={Contact}/>
                        <Route exact path="/about/adve" component={Adve}/>
                        <Route exact path="/about/law" component={Law}/>
                    </Switch>
                </div>
            </div>
        </div>
    );
}