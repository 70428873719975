import * as R from 'ramda';
/* 设置静态常量 */
/* start 用户权限类常量 start */
import React from "react";

/** 静态资源路径 */
export const resourcesUrl = 'https://img.resources.com.cn/';
/** 普通用户 */
export const USER = 0;
/** 管理员 */
export const USER_ADMIN = 1;
/** 超级管理员 */
export const USER_ = 2;

/** 供应商 */
// export const SUPPLIER  = 1;
/** 招标方 */
export const INVITER  = 2;
/** 供应商兼招标方 */
export const SUPPLIER_AND_INVITER = 3;
/** 招标代理 */
export const AGENT = 4;


/* end 用户权限类常量 end */
/** 等待 */
export const AWAIT = 0;
/** 通过 */
export const SUCCESS = 1;
/** 拒绝 */
export const FAIL = 2;
/** 结束 */
export const OVER = 3;
/* start 状态常量 start */

/* end 状态常量 end */


/* start 招标类常量 start */
/** 招标 */
export const TYPE_BIDDING = 1;
/** 采购 */
export const TYPE_PROCUREMENT = 2;
/** 公开招标 */
export const TYPE_COMMON = 1;
/** 邀请招标 */
export const TYPE_INVITE = 2;

/** 线上 */
export const MODE_ON = 1;
/** 线下 */
export const MODE_OFF = 2;
/* end 招标类常量 end */

/*start 供应商审批人 1=经办人，2=招标公告审批人，3=供应商审批人，4=招标文件审批人，5=开标审批人，6=评标审批人，7=定标人，8=中标公示审批人，9=中止招标审批人start*/
/**  经办人   */
export const AUDIT_OPERATOR = 1;

/**  招标公告审批   */
export const AUDIT_PUBLISH = 2;

/**  供应商审批   */
export const AUDIT_SUPPLIER = 3;

/**  招标文件审批人   */
export const AUDIT_SEND = 4;

/**  开标审批   */
export const AUDIT_OPEN = 5;

/**  评标审批   */
export const AUDIT_COMMENT = 6;

/**  定标审批   */
export const AUDIT_CONFIRM = 7;

/**  中标公示审批   */
export const AUDIT_CONFIRM_PUBLISH = 8;

/**  中止招标审批人   */
export const AUDIT_STOP = 9;

/** 供应商 */
export const SUPPLIER = 10;
/*end 供应商审批人 end*/

/* 审批状态 */
export const APPROVAL = 0;

/** 发布招标文件 */
export const PUBLISH_FILE = 12;

/* 常量 */
/** 林地类型 */
export const FOREST_TYPE = [{name:'有林地',value: 1},
    {name:'灌木林地',value: 2},
    {name:'乔木林地',value: 3},
    {name:'疏林地',value: 4},
    {name:'苗圃地',value: 5},
    {name:'宜林地',value: 6},
    {name:'无立木林地',value: 7},
    {name:'未成林造林地',value: 8},
    {name:'辅助生产林地',value: 9},
    {name:'其他林地',value: 20},
];
/** 流转类别 */
export const FORESTRY_TYPE =[{name:'转让',value: 1},{name:'出租',value: 2},{name:'转包',value: 3},{name:'合作',value: 4},{name:'置换',value: 5}];
export const getForestryType = (value)=>{
    return R.find(R.propEq('value', value))(FORESTRY_TYPE) || {};

};
export const PLAN_TYPE ={1:'森林康养',2:'空间规划',3:'产业策划',4:'景观设计',5:'特色小镇'};

export const TYPE = {0:'暂无分类',1:"新闻快讯",2:"通知公告",3:"政策法规",4:"培训交流",5:"林业百科",6:"专题解读",10:'专家资讯'};
/** 预期价格 */
export const FORESTRY_PRICE = [{name:'不限',value: 0},{name:'100万以内',value: 1},{name:'100-1000万',value: 2},{name:'1000万-3000万',value: 3},{name:'3000万以上',value: 4}];
/** 林地面积 */
export const FOREST_AREA = [{name:'不限',value: 0},{name:'100亩以内',value: 1},{name:'100-1000亩',value: 2},{name:'1000-5000亩',value: 3},{name:'5000亩以上',value: 4}];
/** 发布时间 */
export const FOREST_TIME =[{name:'不限',value: 0},{name:'今天',value: 1},{name:'本周',value: 2},{name:'本月',value: 3},{name:'近半年',value: 4}];


export const PROJECT_URL = 'http://project.china-forest.com'; //百大项目库
export const TENDER_URL = 'http://tender.china-forest.com'; //招投标
export const EXPERT_URL = 'http://expert.china-forest.com'; //专家智库
export const MASTER_URL = 'http://www.china-forest.com'; //主平台


// export const PROJECT_URL = 'http://new_project.china-forest.com'; //百大项目库
// export const TENDER_URL = 'http://new_tender.china-forest.com'; //招投标
// export const EXPERT_URL = 'http://new_expert.china-forest.com'; //专家智库
// export const MASTER_URL = 'http://new.china-forest.com'; //主平台