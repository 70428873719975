import React, {Fragment, useEffect, useState} from 'react';
import {useEffectOnce} from 'react-use';
import {Breadcrumb,} from '../../components';
import {DownOutlined} from '@ant-design/icons';

import {useDataApi} from '../../hook';
import css from './index.module.less';
import defaultImg from 'aliasimgurl/default_img.png';
import expertBanner from 'aliasimgurl/banner_expert.png';
import {useHistory} from 'react-router-dom'
import {ExpertHotList} from "../../components/expert-hot-list";
import classNames from 'classnames';
import {resourcesUrl} from "../../common/static_number";


export const ExpertView = (props) => {

    const {id=''} = props.match.params? props.match.params :{};
    const [searchValue, setSearch] = useState({page:1,pagesize:10,newstype:2});
    const [{...detail}] = useDataApi({url: '/api/edetail', method: 'post', data: {id}});
    const [page,setPage] = useState(1);
    const {push} = useHistory();
    const [ { data, setData,...list },updateParams ]  =useDataApi({});
    const [ { data:nextPage,isLoading },getNextPage ]  =useDataApi({});
    useEffectOnce(() => {
        function scrollToTop(argument) {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 8);
            };
        };
        scrollToTop();
    });
    useEffect(()=>{
        updateParams({url: '/api/expertnews', method: 'post', data: {expert_id:id,...searchValue},initialData:[]})
    },[id]);
    useEffect(() => {
        if(page !== 1){
            getNextPage({url:'/api/expertnews',method:'post',data:{expert_id:id, ...searchValue,...{page}}});
        }
    }, [page]);
    useEffect(() => {
        if(!isLoading){
            let _data = data;
            _data.data = _data.data.concat(nextPage.data);
            setData({..._data});
        }
    }, [nextPage,isLoading]);


    function handleNextPage (){
        setPage(page+1);
    }

    function toView(opt) {
        const {id=''} = opt;
        push({pathname:'/expert/info/view/'+id})
    }
    return (
        <div >
            {/*<YcImage imgUrl={ headerImg } />*/}
            {!detail.isLoading && <Fragment>
                <div className="container">
                    <Breadcrumb list={ [{name:'专家智库',path:'/expert'},{name:'专家风采',path:'/expert/list'},{name:  "风采详情"}] } />
                </div>
                <div style={{background: '#fff'}}>
                    <div className="container">
                        <div className={css.bodyWarp}>
                            <div className={css.leftWarp}>
                            { detail.data &&
                                    <div className={css.user}>
                                        <img className={css.avatar} src={detail.data.images[0]  ? resourcesUrl + detail.data.images[0].file : defaultImg} alt=""/>
                                        <div className={css.userInfo}>
                                            <div className={css.name}>{detail.data.nickname}  {detail.data.position}</div>
                                            <div className={classNames(css.des,'lin3')}>
                                                {detail.data.remark}
                                            </div>
                                        </div>

                                    </div>
                            }
                                <div className={css.userList}>
                                    {
                                        !list.isLoading && data.data.map(item=> <div className={css.item} onClick={()=>toView(item)}>
                                                <div className={classNames(css.title)}>
                                                    <span className={'line1'}>{item.title}</span>
                                                    <span className={classNames(css.time,'clearfix') }>{item.createtime}</span>
                                                </div>
                                                <div className={classNames(css.des,'lin3')}>
                                                    {item.remark}
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                                {page*searchValue.pagesize <= data.count && <div className={css.more} >
                                    <span onClick={handleNextPage}>点击加载更多&nbsp;<DownOutlined style={{fontSize : '10px'}}/></span>
                                </div>}
                            </div>
                            <div className={css.rightWarp}>
                                <div className={css.bannerWarp}>
                                    <img src={expertBanner} alt=""/>
                                </div>
                                <ExpertHotList style={{marginTop:'13px'}}/>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
            }
        </div>


    )
}