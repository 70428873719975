import React from 'react';
import css from './footer.module.less';
import {useHistory} from "react-router-dom";
import ga from 'aliasimgurl/ga.png';
import QRcode from 'aliasimgurl/QR_code.png';
import {TENDER_URL} from "../common/static_number";

export const Footer = ()=>{
    const {push} = useHistory();
    return (
        <div className={ css.footer }>
            <div className={css.ext}>
                <div className="container">
                    <div className={css.content}>
                        <div className={css.extRight}>
                            <div>
                                <div className={css.title}>关于我们</div>
                                <div className={css.row}>
                                    <a href="/about/home">平台介绍</a>
                                    <a href="/about/contact">联系我们</a>
                                </div>
                                <div className={css.row}>
                                    <a href="/about/adve">广告服务</a>
                                    <a href="/about/law">法律声明</a>
                                </div>
                            </div>
                            <div>
                                <div className={css.title}>特色功能</div>
                                <div className={css.row}>
                                    <a href="/exchange">寻找林地</a>
                                    <a href={TENDER_URL + '/publish'} target={"_blank"}>发布招标</a>
                                    <a onClick={()=>push({pathname:'/info',state:{type:3}})}>政策法规</a>
                                </div>
                                <div className={css.row}>
                                    <a href="/exchange">林权流转</a>
                                    <a href={TENDER_URL + '/market'} target={"_blank"}>在线投标</a>
                                    <a onClick={()=>push({pathname:'/info',state:{type:4}})}>培训交流</a>
                                </div>
                                <div className={css.row}>
                                    <a href="#">林地评估</a>
                                    <a href="/design">规划设计</a>
                                    <a onClick={()=>push({pathname:'/design/list',state:{type:3}})} >产业策划</a>
                                </div>
                            </div>
                            <div >
                                <div className={css.title}>会员中心</div>
                                <div className={css.row}>
                                    <a href="/reg">会员注册</a>
                                </div>
                                <div className={css.row}>
                                    <a href="/vip">会员服务</a>
                                </div>
                            </div>
                        </div>
                        <div className={css.extLeft}>
                            <div className={css.contact}>
                                <div>服务热线：010 - 53673671</div>
                                <div>投诉建议：digital2023@163.com</div>
                            </div>
                            <div className={css.QRcode}>
                                <img src={QRcode} alt=""/>
                                <div className={css.text}>官方微信</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className={ css.footerBottom }>
                <div>
                    © 2020 版权所有 中国林业产业服务与交易平台 京ICP备20019858号-1
                </div>
                <div>
                    增值电信业务经营许可证：京20204808号
                </div>
                <div>
                    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102004231"><img src={ga} /><span>京公网安备 11010102004231号</span></ a>
                </div>
            </div>
        </div>
    );
}