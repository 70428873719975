import React from 'react';
import css from './new-item.module.less';
import defaultImg from 'aliasimgurl/default_img.png'
import {PLAN_TYPE, resourcesUrl} from "../../../common/static_number";

export const NewItem =(props)=>{
    const {item={},goDetail} = props;
    return (
        <div className={ css.item } onClick={()=>goDetail(item)}>
            <img src={item.images[0] ? resourcesUrl + item.images[0] : defaultImg} alt=""/>
            <div className={css.box}>
                <div className={ css.title }>{ item.title }</div>
                <div className={ css.footer }>{PLAN_TYPE[item.plan_type]}</div>
            </div>
        </div>
    );
}