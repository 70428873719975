import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {Breadcrumb} from '../../components';
import {useDataApi} from '../../hook/dataApi';
import css from './list.module.less';
import {ObjBox} from "./components";
import expertBanner from 'aliasimgurl/banner_expert.png'
import {ExpertHotList} from "../../components/expert-hot-list";
import {DownOutlined} from '@ant-design/icons';


export const ExpertList = (props) => {
    let { push } = useHistory();
    const {type=7} = props.location.state || {};

    const [searchValue, setSearch] = useState({page:1,pagesize:8,type});
    const [page,setPage] = useState(1);
    useEffect(()=>{
        let _s= props.location.state;
        if(_s && Object.keys(_s).length){
            setSearch({...searchValue,..._s});
        }
        scrollToTop();
    },[props.location.state]);
    function scrollToTop(argument) {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 2);
        };
    };

    const [ { data, setData ,...dataBox},updateParams ]  =useDataApi({});
    const [ { data:nextPage,isLoading },getNextPage ]  =useDataApi({});

    useEffect(() => {
        updateParams({url:'/api/experts',method:'post',data:{ ...searchValue}});
    }, [searchValue]);
    useEffect(() => {
        if(page !== 1){
            getNextPage({url:'/api/experts',method:'post',data:{ ...searchValue,...{page}}});
        }
    }, [page]);
    useEffect(() => {
        if(!isLoading){
            let _data = data;
            _data.data = _data.data.concat(nextPage.data);
            setData({..._data});
        }
    }, [nextPage,isLoading]);

    function toView(opt) {
        const {id=''} = opt;
        push({pathname:'/expert/view/'+id})
    }
    function handleNextPage (){
        setPage(page+1);
    }
    console.log("data",data);
    return (
        <div>
            <div className="container">
                <Breadcrumb list={ [{name:'专家智库',path:'/expert'},{name:'专家风采'}] } />
            </div>
        <div style={{padding: '0 0 20px 0'}}>
            <div className="container">
                <div className={css.bodyWarp}>
                    <div className={css.leftWarp}>
                        <div className={ css.listWrap }>
                            {!dataBox.isLoading &&  <ObjBox data={data.data}/>}

                            {page*searchValue.pagesize <= data.count && <div className={css.more} >
                                <span onClick={handleNextPage}>点击加载更多&nbsp;<DownOutlined style={{fontSize : '10px'}}/></span>
                            </div>}
                        </div>
                    </div>
                    <div className={css.rightWarp}>
                        <div className={css.bannerWarp}>
                            <img src={expertBanner} alt=""/>
                        </div>
                        <ExpertHotList style={{marginTop:'13px'}}/>
                    </div>
                </div>

            </div>
        </div>
        </div>
    );
}