import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {Breadcrumb, HotInfoList, YcPagination} from '../../components';
import {useDataApi} from '../../hook/dataApi';
import css from './list.module.less';
import {List} from "./components";
import classNames from 'classnames';
import banner02 from 'aliasimgurl/banner_02.png'


export const DesignList = (props) => {
    let { push } = useHistory();
    const {state:{type=1}={}} = props.location;

    const [searchValue, setSearch] = useState({page:1,pagesize:10,plan_type:type});
    const [page,setPage] = useState(1);
    useEffect(()=>{
        let _s= props.location.state;
        if(_s && Object.keys(_s).length){
            setSearch({...searchValue,..._s});
        }
        scrollToTop();
    },[props.location.state]);
    function scrollToTop(argument) {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 2);
        };
    };
    function updateValue(value) {
        let _v = { ...searchValue, ...value };
        setSearch(_v);
    }

    const [ { data, setData },updateParams ]  =useDataApi({});
    const [ { data:nextPage,isLoading },getNextPage ]  =useDataApi({});

    useEffect(() => {
        updateParams({url:'/api/planslist',method:'post',data:{ ...searchValue}});
    }, [searchValue]);
    useEffect(() => {
        if(page !== 1){
            getNextPage({url:'/api/planslist',method:'post',data:{ ...searchValue,...{page}}});
        }
    }, [page]);
    useEffect(() => {
        if(!isLoading){
            let _data = data;
            _data.list = _data.list.concat(nextPage.list);
            setData({..._data});


        }
    }, [nextPage,isLoading]);
    function toView(opt) {
        const {id=''} = opt;
        push({pathname:'/design/view/'+id})
    }
    function handleNextPage (){
        setPage(page+1);
    }
    return (
        <div>
            <div className="container">
                <Breadcrumb list={ [{name:'规划设计'}] } />
            </div>
        <div style={{background: '#fff',padding: '20px 0'}}>
            <div className="container">
                {/*<YcImage imgUrl={ headerImg } onClick={goDetail}  style={{'cursor': 'pointer'}}/>*/}
                <div className={css.bodyWarp}>
                    <div className={css.leftWarp}>
                        <div className={ css.listWrap }>
                            {/*//1=森林康养,2=空间规划,3=产业策划,4=景观设计,5=特色小镇*/}
                            <div className={css.tabs}>
                                <div className={classNames(css.tab,searchValue.plan_type === 1 ? css.active:'')} onClick={()=>updateValue({plan_type: 1})} >
                                    森林康养
                                    <div className={css.border} />
                                </div>
                                <div className={classNames(css.tab,searchValue.plan_type === 2 ? css.active:'')} onClick={()=>updateValue({plan_type: 2})}>
                                    空间规划
                                    <div className={css.border} />
                                </div>
                                <div className={classNames(css.tab,searchValue.plan_type === 3 ? css.active:'')} onClick={()=>updateValue({plan_type: 3})}>
                                    产业策划
                                    <div className={css.border} />
                                </div>
                                <div className={classNames(css.tab,searchValue.plan_type === 4 ? css.active:'')} onClick={()=>updateValue({plan_type: 4})}>
                                    景观设计
                                    <div className={css.border} />
                                </div>
                                <div className={classNames(css.tab,searchValue.plan_type === 5 ? css.active:'')} onClick={()=>updateValue({plan_type: 5})}>
                                    特色小镇
                                    <div className={css.border} />
                                </div>

                            </div>
                            <List itemsLength={ 16 } data={ data.list || [] } count={data.count} toView={ toView } />
                            {page*searchValue.pagesize <= data.count && <div className={css.more} >
                                <span onClick={handleNextPage}>加载更多</span>
                            </div>}
                        </div>
                    </div>
                    <div className={css.rightWarp}>

                        <HotInfoList style={{marginTop:'13px'}}/>
                        <div className={css.bannerWarp} onClick={()=>push({pathname:'/addproject',state:{}})}>
                            <img src={banner02} alt=""/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </div>
    );
}