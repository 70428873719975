import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CardBox, YcPagination} from '../../components';
import {useDataApi} from '../../hook/dataApi';
import css from './index.module.less';
import {InfoSwiper, List} from "./components";
import classNames from 'classnames';
import {SubjectList} from "../../components/subject-list";
import iconHeadline from 'aliasimgurl/icon_headline.png'


export const Info = (props) => {
    let { push } = useHistory();
    const [searchValue, setSearch] = useState({type:1,page:1,pagesize:10});
    useEffect(()=>{
        let _s= props.location.state;
        if(_s && Object.keys(_s).length){
            setSearch({isLoading:true,...searchValue,..._s});
        }else{
            setSearch({isLoading:true,...searchValue})
        }
    },[props.location.state]);

    function updateValue(value) {
        let _v = { ...searchValue, ...value };
        setSearch(_v);
    }

    const [ { isLoading,...listData },updateParams ]  =useDataApi({});
    const [ { ...newlist } ]  =useDataApi({url:'/api/newsindex',method:'post',data:{}});
    useEffect(() => {
        if(searchValue.isLoading){
            window.scrollTo(0, 575);
            updateParams({url:'/api/newsList',method:'post',data:{ ...searchValue}});
        }

    }, [searchValue]);
    function toView(opt) {
        const {id=''} = opt;
        push({pathname:'/info/view/'+id})
    }
    function pageChange (v){
        updateValue({page:v})
    }
    return (
        <div style={{background: '#fff',padding: '30px 0'}}>
            <div className="container">
                {/*<YcImage imgUrl={ headerImg } onClick={goDetail}  style={{'cursor': 'pointer'}}/>*/}
                <div className={css.bodyWarp}>

                    <div className={css.leftWarp}>
                        {
                            !newlist.isLoading &&   <InfoSwiper data={newlist.data} toView={toView}/>
                        }
                        <div className={ css.listWrap }>
                            <div className={css.tabsBox}>
                                <div className={css.tabs}>
                                    <div className={classNames(css.tab,searchValue.type === 1 && css.active)} onClick={()=>updateValue({type:1})}>
                                        新闻快讯
                                        <div className={css.border} />
                                    </div>
                                    <div className={classNames(css.tab,searchValue.type === 2 && css.active)} onClick={()=>updateValue({type:2})}>
                                        通知公告
                                        <div className={css.border} />
                                    </div>
                                    <div className={classNames(css.tab,searchValue.type === 3 && css.active)} onClick={()=>updateValue({type:3})}>
                                        政策法规
                                        <div className={css.border} />
                                    </div>
                                    <div className={classNames(css.tab,searchValue.type === 4 && css.active)} onClick={()=>updateValue({type:4})}>
                                        培训交流
                                        <div className={css.border} />
                                    </div>
                                    <div className={classNames(css.tab,searchValue.type === 5 && css.active)} onClick={()=>updateValue({type:5})}>
                                        林业百科
                                        <div className={css.border} />
                                    </div>
                                </div>
                            </div>
                            <List itemsLength={ 16 } data={ listData.data.data || [] } count={listData.data.count} toView={ toView } />
                            <YcPagination
                                total={ listData.data.count || 0 }
                                current={ searchValue.page}
                                onChange={pageChange}
                                style={ {margin:'30px 0'} } />
                        </div>
                    </div>
                    <div className={css.rightWarp}>
                        <div className={css.headline}>
                            <img src={iconHeadline} alt="" className={css.img} />
                            <div className={css.header}>头条</div>
                            {
                                !newlist.isLoading &&  newlist.data.hot.map((obj)=> <div key={obj.title} className={css.item} onClick={()=>toView(obj)}>
                                   {obj.title}
                                </div>)
                            }
                        </div>
                        <CardBox title={'专题解读'}>
                            <SubjectList  items={newlist.data.zhuanti}/>
                        </CardBox>
                    </div>
                </div>

            </div>
        </div>
    );
}
