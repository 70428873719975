import React, {useState} from 'react';
import Swiper from 'react-id-swiper';
import css from './index-focus.module.less';
import {Form, Input,Button,Modal} from 'antd';
import {useHistory} from "react-router-dom";
import {useIsLogin} from "../../../hook";
import {SearchBox} from "./search-box";


export const IndexFocus =(props)=>{

    const {banners=[],goDetail} = props;

    const onError = (e)=>{
        console.log(e);

    }
    const [swiper, updateSwiper] = useState(null);
    const {push} = useHistory();
    const params = {
        spaceBetween: 30,
        effect: 'fade',
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        getSwiper: updateSwiper
    }

    const handleMouseOver = ()=>{
        swiper.autoplay.stop()
    };
    const handleMouseOut = ()=>{
        swiper.autoplay.start()
    };
    const [isLogin] = useIsLogin();

    const onsubmit = (v)=>{
      console.log("v===",v);
        if(isLogin){
            push({pathname:'/addproject',state:{...v}});
        }else{
            Modal.warning({
                title:'温馨提示',
                content: '您还未登录，是否现在登录？',
                okText:'去登录',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    window.location.href = '/login'
                },
                onCancel:()=>{

                }
            });

        }
    };

    return (

        <div className={ css.list } >
            <div className={css.leftBox}>
                <SearchBox />
            </div>
            <div className={css.swiper}>
                {banners.length && <Swiper { ...params } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                    {
                        banners.map((opt,index)=>
                            <div className={ css.item } key={ index } onClick={()=>goDetail('banner',opt)}>
                                <img style={ {fontSize:0,display:'block'} }
                                     onError={ (e)=>onError(e) } src={ opt.images } alt="图片"/>
                            </div>
                        )
                    }
                </Swiper>
                }
            </div>


        </div>

    );
}