import React from 'react';
import classNames from 'classnames';
import css from './pro-list.module.less';

export const ProList =(props)=>{

    const {items=[],goDetail} = props;
    return (
        <div className={css.list}>
            <div className={classNames(css.item, css.tableTitle)}>
                <div className={css.title}>项目名称</div>
                <div className={css.regin}>林地地区</div>
                <div className={css.type}>行业分类</div>
                <div className={ css.time }>发布时间</div>
            </div>
            {
                items.map((opt, index) =>
                    <div className={css.item} key={index} >
                        <div className={css.title} onClick={() => goDetail('project',opt)}>{opt.name}</div>
                        <div className={css.regin}>{opt.pname}</div>
                        <div className={css.type}>{opt.industry_name}</div>
                        <div className={ css.time }>{ opt.releasetime }</div>
                    </div>
                )
            }
        </div>
    );

}