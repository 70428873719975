import React from 'react';
import {useHistory} from 'react-router-dom';
import css from './obj-box.module.less';
import {resourcesUrl} from "../../../common/static_number";
import defaultImg from  'aliasimgurl/default_img.png';

export const ObjBox =(props)=>{
    const { data,type,style } = props;
    const {push} = useHistory();
    const goDetail = (item) =>{
        const {id} = item;
        push('/expert/view/'+id);
    }
    return (
        <div className={ css.box } style={style}>
            <div className={css.list}>
                {
                    data.map(item=>
                        <div className={css.enum} style={{width: type? '204px': '172px'}} onClick={()=>goDetail(item)}>
                            <div className={css.avatar}>
                                <img  src={ item.images[0] ? resourcesUrl + item.images[0].file : defaultImg} alt=""/>
                            </div>
                            <div className={ css.title }>{item.username}</div>
                            <div className={ css.subtitle }>{item.position}</div>
                            <div className={ css.content }>{item.remark}</div>

                        </div>
                    )
                }
            </div>
        </div>
    );
}