import React, {Fragment} from 'react';
import css from './box.module.less';

export const Box =(props)=>{
    const { children,title,style,id } = props;
    return (
        <div className={ css.box } style={style} id={id}>
            <div className={ css.title }>
                <span>
                     {title}
                </span>

            </div>
            <div className={'container'}>
                {children}
            </div>
        </div>
    );
}