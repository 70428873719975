import React from 'react';
import css from './userauth.module.less';
import {Steps} from 'antd';
const { Step } = Steps;
export const UserAuthSteps = (props)=>{
    const { currentStep=0,children } = props;
    return (
        <div className={ css.stepWrap }>
            <Steps current={currentStep} className={css.steps}>
                <Step title="填写信息" />
                <Step title="等待审批" />
                <Step title="完成认证"/>
            </Steps>
            <div>{children[currentStep]||children}</div>
        </div>
    );
}