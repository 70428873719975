import React from 'react';
import css from './list.module.less';
import banner01 from 'aliasimgurl/banner_01.png'
import {getForestryType, resourcesUrl} from "../../../common/static_number";
import defaultImg from 'aliasimgurl/default_img.png';
import {createFromIconfontCN} from '@ant-design/icons';
import {EmptyData} from "../../../components/empty-data";

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_i3ev7426x1e.js',
});
export const List =(props)=>{
    const { data =[] ,count=0,toView} = props;
    const goDetail = (opt)=>{
        toView(opt);

    }
    return (
        <div >
            <div className={ css.list }>
                {
                    data.length>0?  data.map((opt,index)=>
                        <div className={ css.item } key={ index }  >
                            <img src={opt.images[0].file ? resourcesUrl + opt.images[0].file : defaultImg} alt="" className={css.img} onClick={()=>goDetail(opt)}/>
                            <div className={css.content}>
                                <div className={ css.title } onClick={()=>goDetail(opt)}>
                                    {opt.project_name}
                                </div>
                                <div className={css.ext}>
                                    <span>预期总价：{opt.forestry_price === 0 ?  '面议':opt.forestry_price + '万'}</span>
                                    <span>使用年限：{ opt.years }年</span>
                                    <span>林地面积：{ opt.measure_area }亩</span>
                                    <span>流转类别：{getForestryType(opt.forestry_type).name  }</span>
                                </div>
                                <div className={css.footer}>
                                    <div><IconFont type={'icon-weizhi'} className={css.icon}/><span>{opt.pname}</span></div>
                                    <div><IconFont type={'icon-time'} className={css.icon} /><span>{opt.releasetime}</span></div>
                                </div>
                            </div>


                        </div>
                    ) : <EmptyData />
                }
            </div>
        </div>

    );
}
