import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

import {Button} from 'antd';
import {Card} from '../components';
import {UserAuthForm} from './UserAuthForm';
import {UserAuthSteps} from './UserAuthSteps';
import authReject from 'aliasimgurl/icon_auth_reject.png';
import authSuccess from 'aliasimgurl/icon_auth_success.png';
import authAwait from 'aliasimgurl/icon_auth_await.png';
import css from './userauth.module.less';
import {useDataApi} from "../../../hook";

export const UserAuth = (props)=>{
    const {type=''} = props.match.params ? props.match.params :{};
    const [currentType,setCurrentType] = useState(type);
    const {push} = useHistory();
    const [step,setStep] = useState(0);
    const [{data, isLoading,setData},updateParams] = useDataApi({url: '/api/getAuthentication', method: 'post'});

    const onSubmit = (v)=>{
        updateParams({url: '/api/getAuthentication', method: 'post'});
        setStep(1);
        push('/account/setting/userauth/detail');
    };
    useEffect(()=>{
        const {apply_state=''} = data;
        let _data = data ;
        _data.site = _data.county_id ? [data.province_id,data.city_id,data.county_id]:[]
        setData({..._data});
        if(currentType !== 'auth'){
            switch (apply_state){
                case "1":
                    setStep(1);
                    break;
                case "2":
                    setStep(2);
                    break;
                case "3":
                    setStep(2);
                    break;
                default:;
            }
        }

    },[isLoading]);
    return (
        <div className={ css.formBody }>
            {!isLoading && <Card title="用户认证">
                <UserAuthSteps currentStep={ step } setStep={ setStep }>
                        <div className={ css.editForm }>
                            {!isLoading && <UserAuthForm onSubmit={ onSubmit } data={data}/>}
                        </div>
                        <div>
                            <div className={ css.success }>
                                <img src={authAwait} alt="" className={css.authAwait}/>
                                <div className={ css.tips }>您的资料提交成功，进入平台审核阶段，请耐心等待！</div>
                                <div className={ css.help }>如有其他任何疑问，请致电010 - 53673671！</div>
                            </div>
                        </div>
                        <div className={ css.authRes }>
                            {
                                data.apply_state == 2 && <Fragment>
                                    <div className={ css.success }>
                                        <img src={authSuccess} alt="" className={css.authSuccess}/>
                                        <div className={css.tips}>您的资质认证已审核通过成功！</div>
                                        <div className={ css.help }>如有其他任何疑问，请致电010 - 53673671！</div>
                                    </div>
                                </Fragment>
                            }
                            {
                                data.apply_state == 3 && <Fragment>
                                    <div className={ css.reject } >
                                        <img src={authReject} alt=""  className={css.authReject}/>
                                        <div  className={css.tips}>抱歉,您提交的资料信息审核不通过，请修改后提交！</div>
                                        <div className={ css.item }>
                                            <div className={ css.label }>驳回原因：</div>
                                            <div className={ css.content }>{data.review_msg}</div>
                                        </div>
                                        <div className={ css.help }>如有其他任何疑问，请致电010 - 53673671！</div>
                                        <div className={ css.toDoBtn }>
                                            <Button onClick={()=>setStep(0) } type={"primary"}>去修改</Button>
                                        </div>
                                    </div>

                                </Fragment>
                            }
                        </div>
                    </UserAuthSteps>
            </Card> }
        </div>
    );
}
