import React, {Fragment} from 'react';
import css from './index-box.module.less';

export const IndexBox = (props)=>{
    const { children,title,ext,more,style } = props;
    return (
        <div className={ css.cardbox } style={style}>
            <div className={ css.title } >
                <span>
                     {title}
                </span>
                <div>
                    <small className={ css.ext }>{ ext }</small>
                    {
                        !!more &&
                        <a className={ css.moreBtn } onClick={ more }>更多></a>
                    }
                </div>
            </div>
            <Fragment>
                {children}
            </Fragment>
        </div>
    );
}