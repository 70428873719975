import React from 'react';
import css from './info.module.less';

/** 资讯 */
export const Info =(props)=>{

    const {name,type,goDetail,style} = props;
    return (
        <div className={css.info} onClick={goDetail} style={style}>
            <div className={css.title}>{name}</div>
            <div className={ css.footer }>{type}</div>
        </div>
    );

}