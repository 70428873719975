import React,{useState} from 'react';
import {Input,Select} from 'antd';
import {useHistory} from "react-router-dom";
import css from './index-search.module.less'
import className from 'classnames';
import { SearchOutlined } from '@ant-design/icons';

const { Search } = Input;

export const IndexSearch = () => {

    const {push} = useHistory();
    const [type ,setType] = useState('1');
    const selectChange = (v)=>{
        setType(v)
    }

    const handleSearch = e=>{
        switch (type) {
            case '1':
                push({pathname:'/exchange',state:{project_name:e}})
                break;
            case '2':
                push({pathname:'/info',state:{search_name:e}})
                break;
            default:

        }
    };

    return (
        <div className={css.searchWarp}>
            <div className={css.tabs}>
            <div className={className(css.tab,type === '1' ?css.active: '')} onClick={()=>selectChange('1')}>找林地</div>
            <div className={className(css.tab,type === '2' ?css.active: '')} onClick={()=>selectChange('2')}>资讯</div>
        </div>
            <Search prefix={<SearchOutlined />}
                    style={{width: '350px'}}
                    placeholder="请输入关键字"
                    onChange={ e=>console.log(e.target.value) }
                    onSearch={handleSearch}
                    enterButton="搜索" />
        </div>
    );
};