import React, {Fragment} from 'react';
import {Card} from '../components';
import {Button, Descriptions} from 'antd';
import {useDataApi, useUserInfo} from '../../../hook';
import css from './index.module.less';
import {UploadImage} from "../../../components";

export const Setting = ()=>{
     const [userInfo] = useUserInfo();
    const [{data}] = useDataApi({url: '/api/getAuthentication', method: 'post'});

    // apply_type:	1企业 2个人 3专家
    return (

        <div >
                <Card title={data.apply_type === 1 ?'企业信息':'个人信息'} extra={<Fragment> <a className={css.link} href="/account/setting/mobile">修改手机号</a><a className={css.link} href="/account/setting/password">修改密码</a></Fragment>}>

                    <div className={ css.box }>
                        <div className={css.cell}><span className={css.label}>用户名:</span>{userInfo.nickname}</div>
                        <div className={css.cell}><span className={css.label}>真实姓名:</span>{userInfo.username}</div>
                        <div className={css.cell}><span className={css.label}>联系方式:</span>{userInfo.mobile && userInfo.mobile.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')}</div>
                        <div className={css.cell}><span className={css.label}>行业分类:</span>{data.business_type}</div>

                            {data.apply_type === 1 ? <Fragment>
                                <div className={css.cell}><span className={css.label}>认证状态:</span>企业</div>
                                <div className={css.cell}><span className={css.label}>公司名称:</span>{data.company_name || '未认证'}</div>
                                <div className={css.cell}><span className={css.label}>注册资金:</span>{data.apply_state == 2? data.reg_capital +'(万元)' :'未认证'}</div>
                                <div className={css.cell}><span className={css.label}>注册地址:</span>{data.address}</div>
                                <div className={css.cell}>
                                    <span className={css.label}>企业工商营业执照:</span>
                                    {data.apply_state == 2 ? '已认证': '未认证'}
                                </div>
                            </Fragment> : <Fragment>
                                <div className={css.cell}><span className={css.label}>注册类别:</span>个人</div>
                                <div className={css.cell}><span className={css.label}>身份证号:</span>{data.legal_identity || '未认证'}</div>
                                <div className={css.cell}>
                                    <span className={css.label}>身份证正反面:</span>
                                    {data.legal_identity_cert ? <UploadImage disabled={true} uploadNum={2} value={data.legal_identity_cert}/> :'未上传'}
                                </div>
                                <div className={css.cell}>
                                    <span className={css.label}>手持身份证照片:</span>
                                    { data.legal_identity_cert_un ?
                                    <UploadImage disabled={true} uploadNum={1} value={data.legal_identity_cert_un}/> : '未上传'}
                                </div>
                            </Fragment>}
                        {data.apply_state == '' && <Button type={"primary"} style={{marginLeft: '150px'}} href='/account/setting/userauth/auth' >立即认证</Button> }
                        {data.apply_state == '1'  &&  <Button type={"primary"} style={{marginLeft: '150px'}} href='/account/setting/userauth/detail' >查看</Button>}
                        {data.apply_state == '3'  &&  <Button type={"primary"} style={{marginLeft: '150px'}} href='/account/setting/userauth/detail' >查看</Button>}
                        {data.apply_state == '2'  &&  <Button type={"primary"} style={{marginLeft: '150px'}} href='/account/setting/userauth/auth' >修改</Button>}
                    </div>
                </Card>
            </div>

    )
}