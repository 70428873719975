import React from 'react';
import css from './list.module.less';
import defaultImg from  'aliasimgurl/default_img.png';
import {resourcesUrl} from "../../../common/static_number";
import {Button} from 'antd';
import {EmptyData} from "../../../components/empty-data";
/** 项目资讯 */
export const List =(props)=>{

    const {data,count,toView} = props;

    return (
        <div>
            <div className={css.list} style={{minHeight: '350px'}}>
            {
                count > 0 && data.map((opt, index) =>
                    <div className={css.item} key={opt.id} onClick={() => toView(opt)}>
                        <div className={css.leftWarp}>
                            <img src={opt.images[0] ? resourcesUrl + opt.images[0] : defaultImg} className={css.img} alt=""/>
                        </div>
                        <div className={css.rightWarp}>
                            <div className={css.title}>{opt.title}</div>
                            <div className={css.content}>
                                {
                                    opt.remark.length > 50 ? opt.remark.slice(0, 50) + '...' : opt.remark
                                }
                                <Button onClick={ ()=>{toView(opt.id)} } type='link'>【全文】</Button>
                            </div>

                        </div>


                    </div>
                )
            }
            {
                count != 0 ||   <EmptyData />
            }
        </div>
        </div>
    );

}