import {CService} from "./services";

// import { parse, stringify } from "qs/qs";

export class Service extends CService {
    baseUrl = "";
    devUrl = "";
    domain = "";
    openidName = "token";
    version = "";
    env = "dev";

    doLogin(data){
        return this.POST('login',data);
    }

    doLogout(data){
        return this.POST('logout',data);
    }

    doRegister(data){
        return this.POST('register',data);
    }

    getUserInfo(data){
        return this.POST('userIndex',data);
    }

    getSendCode(data){
        return this.POST('sendCode',data);
    }
    /** 验证手机号码是否存在 */
    verifyMobile(data){
        return this.POST('user/getmobile',data);
    }


    getSigns(data){
        return this.POST('getsigns',data);
    }

    /** 身份认证 */
    authForm(data){
        return this.AXIOS({url:'/api/brokerOpenStore',data,headers:{'Content-Type':"application/json;charset=utf-8"}});

    }
    /**
     *重置密码
     */
    doResetpwd(data){
        return this.POST('resetpwd',data);
    }
    doChangeMobile(data){
        return this.POST('changemobile',data);
    }

    /**
    *找回密码
    */
    doRetrievepwd(data){
        return this.POST('retrievepwd',data);
    }

    applyMember(data){
        return this.POST('applyMember',data);
    }

    getNoticeNum(data){
        return this.POST('allNoticeNum',data);
    }


    /* 文件上传相关接口 */
    fetchUploadToken(data){
        return this.AXIOS({url:'/api/upToken',data});
    }

    /* 发布招标信息接口 --开始  */
    /** 创建项目  */
    addPro(data){
        return this.AXIOS({url:'/api/addForesty',data,headers:{'Content-Type':"application/json;charset=utf-8"}});
    }
    /** 项目评估  */
    assessment(data){
        return this.AXIOS({url:'/api/assessment',data,headers:{'Content-Type':"application/json;charset=utf-8"}});
    }

    /** 保存意见 */
    addProposal(data){
        return this.POST('addProposal',data);
    }

    /** 收藏 */
    collect(data){
        return this.POST('addCollect',data);
    }
    /** 取消收藏 */
    cancelCollect(data){
        return this.POST('delCollect',data);
    }

    /** 增加下载数量 */
    downloadAdd(data){
        return this.POST('addNum',data);
    }

    getCityData(data){
        return this.POST('getCity',data);
    }

    /** 更新消息状态  */
    updateNotice(data){
        return this.POST('user/msgStatus',data);
    }

    /** 更新系统消息状态  */
    resetMessage(data){
        return this.POST('user/upmsgStatus',data);
    }
    /** 更新头像 */
    updateAvatar(data){
        return this.POST('profile',data);
    }

}

export default new Service();
// Service.model = new Service()
