import React from 'react';
import {IndexLogo, IndexSearch} from './index';
import {useHistory} from "react-router-dom";
// import hottelimg from 'aliasimgurl/icon_hot_tel.png';
import css from './index-header.module.less';
import {TENDER_URL} from "../common/static_number";
import iconMobile from "aliasimgurl/icon_mobile.png";

export const IndexHeader = ()=>{

    const {push} = useHistory();
    const goPage =(type)=>{
       switch (type) {
           case 'market': //招标
               window.open(TENDER_URL + '/market');
               break;
           case 'bidding': //采购
               window.open(TENDER_URL + '/bidding');
               break;
       }

    };
    return (
        <div style={{background: '#fff'}}>
            <div className="container">
                <div className={ css.wrap }>
                    <div className={ css.logo } >
                        <IndexLogo  />
                    </div>
                    <div className={ css.searchWrap }>
                        <IndexSearch />
                        {/*<div className={css.tags}>*/}
                        {/*    <span onClick={()=>push('/exchange')}>林地流转</span>*/}
                        {/*    /!*<span onClick={()=>push('/exchange')}>林权评估</span>*!/*/}
                        {/*    <span onClick={()=>goPage('market')}>招标</span>*/}
                        {/*    <span onClick={()=>goPage('bidding')}>采购</span>*/}
                        {/*</div>*/}
                    </div>
                    <div className={css.mobile}>
                        <img src={iconMobile} alt=""/>
                        <div>
                            <div>服务热线电话</div>
                            <div><span>010 - 53673671</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
