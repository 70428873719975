import React, {useEffect, useState} from 'react';
import {Input, Select} from 'antd';
import {useDataApi} from "../hook";

const { Option } = Select;

export const PriceInput = ({ value={}, onChange }) => {
    const [number, setNumber] = useState();
    const [type, setType] = useState(0);
    const onNumberChange = ({target:{value:number}}) => {
        onChange({type,value:number});
        setNumber(number);
    };

    const onTypeChange = (type) => {
        onChange({type:type,value: number});
        setType(type);
    };
    return (
        <div>
          <Select
              value={value.type || type}
              style={{
                  width: value.type === 1 ?'100%':'49%',
                  marginRight: '6px',
              }}
              onChange={onTypeChange}
          >
              <Option value={0}>自定义</Option>
              <Option value={1}>面议</Option>
          </Select>
            { value.type !==1 &&
            <Input
                type="text"
                value={number}
                onChange={onNumberChange}
                style={{
                    width: '49%',
                }}
                placeholder={'请输入总价'}
            />
            }
    </div>
    );
};