import React, {useEffect, useState} from 'react';
import {Card} from '../components';
import {YcPagination} from '../../../components';
import {useDataApi} from '../../../hook';
import {useHistory} from "react-router-dom";
import css from './index.module.less';
import {PublishModal} from "../../../common";
import iconRead from "aliasimgurl/icon_read.png";
import classNames from 'classnames';
import {EmptyData} from "../../../components/empty-data";

export const Workbench = () => {
    const [dataList, setDataList] = useState([]);
    const [params, setParams] = useState({page:1,category: '4'});
    const [{data: dataSource, isLoading},updateParams] = useDataApi();
    useEffect(() => {
        if (!isLoading) {
            const {data = []} = dataSource;
            setDataList(data);
        }
    }, [isLoading]);
    useEffect(() => {
        updateParams({
            url: '/api/user/NoticeList',
            method: 'post',
            data: {...params}
        });
    }, [params]);
    const {push} = useHistory();
    const goDetail = ({project_id,project_sn,id,category,type,status}) => {


        if(category == '4'){
            const callback = ()=>{
                skip({project_id});
            }
    
            markRead({category,type,status,id,callback});
        }else if(category == '1'||category == '2'||category == '21'||category == '3'){
            const callback = ()=>{
            }
            markRead({category,type,status,id,callback});
            switch (category) {
                case 1 : //审核通知
                    window.open('http://tender.china-forest.com/account/project/audit/?project_sn='+project_sn)
                    break;
                case 21 : //投标信息
                    if (type === 20 || type === 21 || type === 142) { //评标
                        window.open('http://tender.china-forest.com/account/bid/list/?project_sn='+project_sn)
                    } else if (type === 18 || type === 19 || type === 22) { //开标
                        window.open('http://tender.china-forest.com/account/project/list/?project_sn='+project_sn+'&type=5')
                    } else {
                        window.open('http://tender.china-forest.com/account/project/list/?project_sn='+project_sn)
                    }
                    break;
                case 22 : //招标信息
                    window.open('http://tender.china-forest.com/account/project/list/?project_sn='+project_sn+'&type=3')
                    // push({pathname: '/account/project/list', state: {project_sn, type: '3'}});
                    break;
                case 3 : //采购信息
                    window.open('http://tender.china-forest.com/account/project/list/?project_sn='+project_sn+'&type=2')
                    // push({pathname: '/account/project/list', state: {project_sn, type: '2'}});
                    break;
                default:
                    break;
            }
        }
        
        // category 类别 1=审核通知  2=招标通知 21=招标信息 22=投标信息 3=采购信息 4=系统通知  5=专家评分邀请 6=专家评分提醒 7=专家系统通知 8=企业系统通知

    };
    const markRead = ({category,type,status,id,callback=()=>{}},index=null)=>{
        if(!status){
            PublishModal.updateNotice({project_notice_id:id}).pipe().subscribe({
                next:res=>{
                    callback();
                    if(index !== null){
                        const _dataList = dataList;
                        _dataList[index].status = 1;
                        setDataList(_dataList);
                    }
                },
                error:err=>{},
                complete:()=>{}
            });
        }else{
            callback();
        }
    };
    const typeChange = (e)=>{
        let  _params = params;
        _params.category = e;
        setParams({..._params})
    }
    const skip = ({project_id})=>{
        push({pathname: '/account/project/list',state:{project_sn:project_id}});
    }

    const allReady = ()=>{
        PublishModal.resetMessage({type: params.category}).pipe().subscribe({
            next:res=>{
                window.location.reload();
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    return (
        <div className={css.mainBody}>
            {!isLoading && <Card title="消息通知" extra={<div className={css.read} onClick={allReady}><img className={css.icon} src={iconRead} alt=""/><span>全部标为已读</span></div>}>

                <div className={css.tabs}>
                    <div className={classNames(css.tab ,params.category === '4' ? css.active :'')} onClick={()=>typeChange('4')}>系统通知</div>
                    <div className={classNames(css.tab ,params.category === '2' ? css.active :'')} onClick={()=>typeChange('2')}>招标通知</div>
                    <div className={classNames(css.tab ,params.category === '3' ? css.active :'')} onClick={()=>typeChange('3')}>采购通知</div>
                    <div className={classNames(css.tab ,params.category === '1' ? css.active :'')} onClick={()=>typeChange('1')}>审批通知</div>
                    <div className={classNames(css.tab ,params.category === '9' ? css.active :'')} onClick={()=>typeChange('9')}>项目库通知</div>
                </div>
                <div className={css.info}>
                    <div className={css.row}>
                        <div className={css.title}>通知</div>
                        <div className={css.time}>时间</div>
                        <div className={css.status}>状态</div>
                        <div className={css.action}>操作</div>
                    </div>
                    {
                        dataList.map((opt,index) =>
                            <div key={opt.id} className={css.row}>
                                <div className={classNames(css.title,"line2")}>{opt.content}</div>
                                <div className={css.time}>{opt.createtime}</div>
                                <div className={css.status} style={{color: !opt.status? '#E5574C':''}}>{opt.status_text}</div>
                                 <div className={css.action}>
                                    <div className={css.btn} onClick={()=>goDetail(opt)}>查看</div>
                                </div>
                            </div>
                        )
                    }
                    {
                        !isLoading &&  !dataList.length && <EmptyData />
                    }
                    <YcPagination style={ {margin:'30px 0'} }  total={ dataSource.total || 0 }
                                  current={ params.page }
                                  onChange={(e)=> {const _params = params;_params.page=e; setParams({..._params})}}/>
                </div>
            </Card>
            }
        </div>
    )
}