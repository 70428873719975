import React from 'react';
import classNames from 'classnames';
import css from './DesignList.module.less';
import {useHistory} from "react-router-dom";
import {EmptyData} from "../../../components/empty-data";

export const DesignList = (props)=>{
    const {data=[],handleCancelCollect} = props;
    const {push} = useHistory();
    const goDetail = (opt)=>{
        const {cid:id} = opt;
        push({pathname:'/design/view/'+ id});
    };
    return (
        <div className={css.box}>
            <div className={css.list}>
                <div className={classNames(css.header)}>
                    <div className={css.name}>标题</div>
                    <div className={css.status} >规划类别</div>
                    <div className={css.time} >发布时间</div>
                    <div className={css.operation}>操作</div>
                </div>
                { data.map((opt,index)=>
                    <div className={classNames(css.row)}>
                        <div className={css.name}  onClick={()=>goDetail(opt)}>
                            {opt.project_name|| '未命名'}
                        </div>
                        <div className={css.status}>{{1:'森林康养',2:'空间规划',3:'产业策划',4:'景观设计',5:'特色小镇'}[opt.plan_type] }</div>
                        <div className={css.time}>{opt.createtime}</div>
                        <div className={css.operation}>
                            <div className={css.btn} onClick={()=>handleCancelCollect(opt,index)}>取消收藏</div>
                        </div>
                    </div>

                )}
                {
                    !data.length && <EmptyData />
                }
            </div>
        </div>


    )
}