import React from 'react';
import css from './pro-box.module.less';
import {ProList,LeftBox,Info} from "./index";
import banner_02 from 'aliasimgurl/banner_02.png';
import defaultImg from  'aliasimgurl/default_img.png'
import {resourcesUrl,PROJECT_URL, TENDER_URL} from "../../../common/static_number";

export const ProBox =(props)=>{
    const {data={}} = props;
    const goDetail= (type,opt)=>{
        switch (type) {
            case 'project': //采购
                window.open(PROJECT_URL + '/obj/view/'+opt.id);
                break;
            case 'info': //资讯详情
                window.open(PROJECT_URL + '/info/view/'+opt.id);
                break;
            default:
        }
    };
    return (
        <div className={css.box}>
            <LeftBox>
                {
                    data.project1.map((opt)=>
                        <div className={css.largeInfo} key={opt.id} onClick={()=>goDetail('project',opt)}>
                            <img src={resourcesUrl + opt.images[0].file} alt="" className={css.banner} />
                            <div className={css.largeTitle}>{opt.name}</div>
                        </div>)
                }
                {
                   data.project2.map((opt)=><Info  goDetail={()=>goDetail('info',opt)} key={opt.title} name={opt.title} type={'项目资讯'} />)
                }
            </LeftBox>
            <div className={css.right}>
                <ProList items={data.project3} goDetail={goDetail}/>
            </div>
        </div>
    );

}