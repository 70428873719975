import React from 'react';
import css from './fine-project-list.module.less';
import banner_02 from 'aliasimgurl/banner_02.png';
import {DefaultItem, LeftBox,NewProjectList} from "./index";
import {useHistory} from "react-router-dom";
import {resourcesUrl, TENDER_URL} from "../../../common/static_number";

/** 精品林地展 */
export const FineProjectList =(props)=>{

    const {data={}} = props;
    const {push} = useHistory();
    const goDetail = (opt)=>{
        push({pathname:'/exchange/view/'+opt.id})
    }
    return (
        <div className={css.box}>
            <LeftBox>

               {data.forest1.map((opt)=> <div className={css.largeInfo} key={opt.id} onClick={()=>goDetail(opt)}>
                   <img src={resourcesUrl + opt.images[0].file} alt="" className={css.banner} />
                   <div className={css.largeTitle}>{opt.project_name}</div>
               </div>)}
                <div className={css.newList}>
                    <NewProjectList  items={data.forest3}/>
                </div>
            </LeftBox>

            <div className={css.items}>
                {data.forest2.map((opt,index)=> <DefaultItem item={opt} key={index}/>)}
            </div>

        </div>
    );

}