import React, {Fragment} from 'react';
import './App.css';
import {EmptyBox, Footer, IndexHeader, TopHeader} from './components';
import {Redirect, Route, Switch} from "react-router-dom";
import {Home} from './pages/home';
import {AddProject} from "./pages/addProject/add-project";
import {ProView} from "./pages/projestList/view";
import {ProList} from "./pages/projestList";
import {ExchangeList} from "./pages/exchange";
import {ExchangeView} from "./pages/exchange/view";

import {Info} from "./pages/info";
import {InfoView} from "./pages/info/view";
import {Account} from "./pages/account";
import {LoginPage} from './pages/login/index';
import {DataTrade} from "./pages/dataTrade";
import {MainNav} from "./router";
import {Design} from "./pages/design";
import {DesignList} from "./pages/design/list";
import {DesignView} from "./pages/design/view";
import {Vip} from "./pages/vip";
import {SubView} from "./pages/info/subiew";
import {useUserInfo} from "./hook";
import {About} from "./pages/about";
import {Expert} from "./pages/expert";
import {ExpertList} from "./pages/expert/list";
import {ExpertView} from "./pages/expert/view";
import {ExpertInfoView} from "./pages/expert/info/view";
import {ExpertInfoList} from "./pages/expert/info/list";
import {Assess} from "./pages/assess";
import {AddAssess} from "./pages/assess/add-assess";
import {Link} from "./components/link";

function App() {

    return (
        <div className="App">
            <Switch>
                <Redirect exact from="/login" to="/login/login" />
                <Redirect exact from="/reg" to="/login/reg" />
                <Redirect exact from="/recover" to="/login/recover" />

                <Route exact path="/login/:currentModal" component={LoginPage}/>
                <Route exact path={['/account','/account/:c1','/account/:c1/:c2','/account/:c1/:c2/:c3']} component={ Account }/>
                <Fragment>
                    { useUserInfo}
                    <TopHeader />
                    <IndexHeader />
                    <MainNav />
                    <Switch>
                        <Redirect exact from="/" to="/index" />
                        <Route exact path="/index" component={Home}/>
                        <Route exact path="/addproject" component={AddProject}/>
                        <Route exact path="/exchange" component={ExchangeList}/>
                        <Route exact path="/assess" component={Assess}/>
                        <Route exact path={["/addAssess",'/addAssess/:id']} component={AddAssess}/>
                        <Route exact path="/exchange/view/:id" component={ExchangeView}/>
                        <Route exact path="/obj/exchequer" component={ProList}/>
                        <Route exact path="/obj/NDRC" component={ProList}/>
                        <Route exact path="/obj/local" component={ProList}/>
                        <Route exact path="/obj/view/:id" component={ProView}/>
                        <Route exact path="/info" component={Info}/>
                        <Route exact path="/info/view/:id" component={InfoView}/>
                        <Route exact path="/info/subview/:id" component={SubView}/>
                        <Route exact path="/data/trade" component={DataTrade}/>
                        <Route exact path="/design" component={Design}/>

                        <Route exact path="/design/list" component={DesignList}/>
                        <Route exact path="/design/view/:id" component={DesignView}/>
                        <Route exact path="/expert" component={Expert}/>
                        <Route exact path="/expert/list" component={ExpertList}/>
                        <Route exact path="/expertInfoList" component={ExpertInfoList}/>
                        <Route exact path="/expert/view/:id" component={ExpertView}/>
                        <Route exact path="/expert/info/view/:id" component={ExpertInfoView}/>
                        <Route exact path="/vip" component={Vip}/>
                        <Route exact path={['/about','/about/:c1']} component={ About }/>

                        <Route key="error" render={ (props) => <EmptyBox /> } />
                    </Switch>
                    <Link />
                    <Footer />

                </Fragment>
            </Switch>
        </div>
    );
}

export default App;