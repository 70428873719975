import React from 'react';
import {Pagination} from 'antd';

export const YcPagination = (props) => {
    const { onChange, style, className,total=1, pageSize= 10,current=1} = props;

    function handleChange(v) {
        onChange && onChange(v);
    }

    return (
        <div >
            {
                total <= pageSize ?  '' : <Pagination showQuickJumper
                                                    className={ className }
                                                                                  current={current}
                                                    style={ {textAlign:'center',marginTop:40, ...style } }
                                                    total={ total } onChange={ handleChange } pageSize={pageSize}/>

            }

        </div>

    );
}