import React from 'react';
import {NavLink ,Link} from 'react-router-dom'
import css from './main-nav.module.less';

export const MainNav = (props) => {

    const mainNav = [
        {
            path:`/index`,
            label:'首页',
        },{
            path:`/exchange`,
            label:'林权流转',
        },{
            path:`/assess`,
            label:'林权评估',
        },{
            path:`/design`,
            label:'规划设计',
        },{
            path:`http://project.china-forest.com/index`,
            label:'百大项目库',
            target:'_black'
        },{
            path:`http://tender.china-forest.com/`,
            label:'招投标',
            target:'_black'
        },{
            path:`/expert`,
            label:'专家智库',
        },{
            path:`/info`,
            label:'资讯中心',
        },{
            path:`/about/home`,
            label:'平台介绍',
        }
    ];
    return (
        <div className={ css['main-nav'] }>
            <div className="container">
                <div className={ css.navList }>
                    {
                        mainNav.map((opt,index)=>{
                            return opt.target?  <a href={ opt.path } target={'_blank'} className={ css.nav } key={'nav'+index}>{ opt.label }</a> : 
                            <NavLink key={'nav'+index} to={ opt.path } className={ css.nav } activeClassName={ css.selected }>
                                <div>
                                    <span>{ opt.label }</span>
                                </div>
                            </NavLink>
                        })
                    }
                </div>
            </div>
        </div>
    );
}