import React, {useState} from 'react';
import Swiper from 'react-id-swiper';
import css from './info-swiper.module.less';
import {useIsLogin} from "../../../hook";
import {useHistory} from "react-router-dom";

import banner01 from 'aliasimgurl/banner_01.png'

export const InfoSwiper =(props)=>{
    const {data:{index:banners = [],recommend=[]},toView} = props;
    const onError = (e)=>{
        console.log(e);

    }
    const [swiper, updateSwiper] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);


    const {push} = useHistory();
    const params = {
        spaceBetween: 30,
        effect: 'fade',
        loop: true,
        hideOnClick: true,
        hiddenClass: 'my-button-hidden',
        autoplay: {
            delay: 300000,
            disableOnInteraction: false
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            // el: '',
            // // clickable: true
        },

        getSwiper: updateSwiper,
        on: {
            init: function(){
                setTimeout(()=>{
                    this.navigation.$nextEl.css('display','none');
                    this.navigation.$prevEl.css('display','none');
                },50)
            },
            slideChange: function () {
                setActiveIndex(this.realIndex);
            },
        }
    };

    const handleMouseOver = ()=>{
        swiper.autoplay.stop();
        swiper.navigation.$nextEl.css('display','block');
        swiper.navigation.$prevEl.css('display','block');
    };
    const handleMouseOut = ()=>{
        swiper.autoplay.start();
        swiper.navigation.$nextEl.css('display','none');
        swiper.navigation.$prevEl.css('display','none');
    };

    const [isLogin] = useIsLogin();
    return (

        <div className={css.swiperBox} >

             <div className={css.swiper} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
               <Swiper { ...params }  >
                    {
                        banners.map((opt,index)=>
                            <div className={ css.item } key={ index } onClick={()=>toView(opt)}>
                                <img style={ {fontSize:0,display:'block'} }
                                     onError={ (e)=>onError(e) } src={ opt.images } alt="图片"/>
                                     <div className={css.title}>{opt.title}</div>
                            </div>
                        )
                    }
                </Swiper>

            </div>
            <div className={css.right}>

                {recommend.map((opt)=> <div className={css.item} onClick={()=>toView(opt)} key={opt.id}>
                        <img style={ {fontSize:0,display:'block'} }
                             onError={ (e)=>onError(e) } src={ opt.images } alt="图片"/>
                        <div className={css.title}>{opt.title}</div>
                </div>
                )}
            </div>
        </div>

    );
}