import React, {Fragment} from 'react';
import css from './box.module.less';

export const Box =(props)=>{
    const { children,title,subtitle,style,id } = props;
    return (
        <div className={ css.box } style={style} id={id}>
            <div className={ css.title }>{title}</div>
            <div className={ css.subtitle }>{subtitle}</div>
            <div className={'container'}>
                {children}
            </div>
        </div>
    );
}