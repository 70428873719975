import React, {Fragment, useEffect, useState} from 'react';
import {Card, DesignList, ForestList, InfoList} from '../components';
import {Input, message} from 'antd';
import {useDataApi} from '../../../hook';
import css from './collect.module.less';
import {ProModal} from "../../../common";
import {tap} from "rxjs/operators";
import {YcPagination} from "../../../components";
import {EmptyData} from "../../../components/empty-data";

const { Search } = Input;

export const Collect = () => {
    const [params, setParams] = useState({page:1,type:'8',total:0});

    const [{data: dataSource, isLoading,setData}, updateParams] = useDataApi({initialData:[]});

    useEffect(() => {
        const {project_name:search_name,type,page} =params;
        updateParams({
            url: '/api/collectList',
            method: 'post',
            data: {page, type,search_name}
        })
    }, [params]);

    const handleTypeChange = (value)=>{
        let _params = params;
            _params.type =value;
            setParams({..._params});

    };
    /** 取消收藏 */
    const handleCancelCollect = (data,index)=>{
        ProModal.cancelCollect({id:data.cid,type:data.type}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('取消成功',3);
                let _data = dataSource;
                    _data.list.splice(index,1);
               setData({..._data});
            },
            error:err=>{},
            complete:()=>{}
        });
    };

    const handleSearch = (value) => {
        let _params = params;
        _params.project_name = value;
        _params.page = 1;
        setParams({..._params});
    };
    return (
        <div className={css.body}>
                <Card title="收藏夹">
                        <div className={css.searchBox}>
                            <div className={css.typeBox}>
                                <div className={params.type === '8'? css.active : ''} onClick={()=>handleTypeChange('8')}>林权流转</div>
                                <div className={params.type === '9'? css.active : ''} onClick={()=>handleTypeChange('9')}>经典案例</div>
                                <div className={params.type === '10'? css.active : ''} onClick={()=>handleTypeChange('10')}>新闻资讯</div>
                                <div className={params.type === '11'? css.active : ''} onClick={()=>handleTypeChange('11')}>专家资讯</div>
                            </div>
                            <Search
                              style={{width:'283px'}}
                                placeholder="请输入项目关键字进行搜索"
                                enterButton="搜索"
                                onSearch={value => handleSearch(value)}
                            />
                        </div>
                        {!isLoading && <Fragment>
                            {params.type === '8' &&  <ForestList data={dataSource.list} handleCancelCollect={handleCancelCollect}/>}
                            {params.type === '9' && <DesignList data={dataSource.list} handleCancelCollect={handleCancelCollect}/>}
                            {params.type === '10' && <InfoList data={dataSource.list} handleCancelCollect={handleCancelCollect}/>}
                            {params.type === '11' && <InfoList data={dataSource.list} handleCancelCollect={handleCancelCollect}/>}
                            <YcPagination
                                total={ params.total || 0 }
                                pageSize={15}
                                current={ params.page }
                                onChange={(e)=> {const _params = params;_params.page=e; setParams({..._params})}}
                                style={ {margin:'30px 0'} } />
                        </Fragment>}

                </Card>
            </div>
    )
}