import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import css from './SubNav.module.less';
import {AppstoreOutlined, ClusterOutlined, SettingOutlined, SoundOutlined, StarOutlined} from '@ant-design/icons';
import {Badge} from "antd";
import {useMessageNumData} from "../../../hook/messageNumData";
import {useUserInfo} from "../../../hook";


export const SubNav = (props)=>{

    const subNav = [{
        label:'平台介绍',
        path:'/about/home'
    },{
        label:'法律声明',
        path:'/about/law'
    },{
        label:'广告服务',
        path:'/about/adve'
    },{
        label:'联系我们',
        path:'/about/contact',

    }];

    return (
        <div className={ css.navList }>
            {
                subNav.map((opt,index)=>
                <NavLink to={ opt.path }  key={ index } className={ css.nav } activeClassName={ css.selected}>
                     { opt.label }
                </NavLink>
                )
            }
        </div>
    )
}