import React from 'react';
import css from './hot-info-list.module.less';
import {useDataApi} from "../hook";
import { useHistory} from 'react-router-dom'

export const HotInfoList =(props)=>{
    // const { items =[], goDetail} = props;
    const {style} = props;
    const [{ data,isLoading }] = useDataApi({ url: '/api/newsList', method: 'post', data: { pagesize:10,flag:'hot', type: 1 ,page:1}, initialData: {} });
    const { push } = useHistory();

    const goDetail = (opt)=>{
        push({pathname:'/info/view/'+opt.id});
    };

    return (
        <div className={ css.card } style={style}>
            <div className={css.title}>
                热点资讯
            </div>
            <div className={css.list}>
                {
                    !isLoading && data.data.map((opt,index)=>
                        <div className={ css.item } key={ index } onClick={()=>goDetail(opt)} >
                            {/*<div className={css.index}>{index+1}</div>*/}
                            <div className={ css.name } >{ opt.title }</div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}