import React from 'react';
import css from './index.module.less';
import {useDataApi} from "../hook";
import {useHistory} from "react-router-dom";
import {BidBox, DesignBox, FineProjectList, IndexBox, IndexFocus, InfoBox, ProBox} from './components/home';
import {NoticesScroll} from "./components/home/notices-scroll";
import {PROJECT_URL, TENDER_URL} from "../common/static_number";

export const Home = (props)=>{
    const [{data,isLoading }] = useDataApi({ url: '/api/forestIndex', method: 'post', initialData: {}});
    const {push} = useHistory();
    const ExtOne = <span><i onClick={()=>goList('market')}>招标信息</i><i onClick={()=>goList('bidding')}>采购信息</i></span>;
    const ExtTwo = <span ><i onClick={()=>goList('exchequer')}>财政部项目</i><i onClick={()=>goList('NDRC')}>发改委项目</i><i onClick={()=>goList('local')}>地方项目库</i></span>;
    const ExtThree = <span ><i onClick={()=>goList('design',{type:1})}>森林康养</i><i onClick={()=>goList('design',{type:2})}>空间规划</i><i onClick={()=>goList('design',{type:3})}>产业策划</i><i onClick={()=>goList('design',{type:4})}>景观设计</i><i onClick={()=>goList('design',{type:5})}>特色小镇</i></span>;
    const ExtFour = <span><i onClick={()=>goList('info',{type:1})}>新闻快讯</i>
        <i  onClick={()=>goList('info',{type:2})}>通知公告</i>
        <i onClick={()=>goList('info',{type:3})}>政策法规</i>
        <i onClick={()=>goList('info',{type:4})}>培训交流</i>
        <i onClick={()=>goList('info',{type:5})}>林业百科</i></span>;


    const goDetail= (type,opt)=>{
        switch (type) {
            case 'bidding': //采购
                window.open(TENDER_URL + '');
                break;

            case 'design': //规划设计
                push({pathname:'/design/view/'+opt.id});
                break;
            case 'info': //资讯详情
                push({pathname:'/info/view/'+opt.id});
                break;
            case 'banner':
                push({pathname:opt.url || '/'});
                break
            default:
        }
    };
    const goList = (type,state)=>{
        switch (type) {
            case 'NDRC': //财政部项目
                window.open(PROJECT_URL + '/obj/NDRC');
                break;
            case 'exchequer': //发改委项目
                window.open(PROJECT_URL + '/obj/exchequer');
                break;
            case 'local': //地方项目
                window.open(PROJECT_URL + '/obj/local');
                break;
            case 'exchange': //精品林地展
                push({pathname:'/exchange',state:{}});
                break;
            case 'project': //百大项目库
                window.open(PROJECT_URL);
                break;
            case 'tender': //招投标网
                window.open(TENDER_URL);
                break;
            case 'market': //招标
                window.open(TENDER_URL + '/market');
                break;
            case 'bidding': //采购
                window.open(TENDER_URL + '/bidding');
                break;
            case 'design': //规划设计
                push({pathname:'/design/list',state});
                break;
            case 'info': //资讯列表
                push({pathname:'/info',state});
                break;
            default:
        }
    };

    return (
        <div className={css.home}>
            {
                !isLoading &&   <div className="container">
                    <div className="container">
                        <IndexFocus banners={data.banners} area={data.area} protype={data.protype} goDetail={goDetail}/>
                    </div>
                    <div className="container">
                        <NoticesScroll items={data.gonggao}/>
                    </div>
                    <IndexBox title="精品林地展" more={ ()=>goList('exchange') }>
                        <FineProjectList data={data}/>
                    </IndexBox>
                    <IndexBox title="招标采购"  more={ ()=>goList('tender') } ext={ExtOne}>
                        <BidBox data={data} />
                    </IndexBox>
                    {
                        data.bannerstwo.map(opt =>  <img src={opt.images} key={opt.id} alt="" className={css.banner}/>)
                    }

                    <IndexBox title="百大项目库"  more={ ()=>goList('tender') } ext={ExtTwo}>
                        <ProBox data={data}/>
                    </IndexBox>
                    <IndexBox title="规划设计"  more={ ()=>goList('design') } ext={ExtThree}>
                        <DesignBox data={data} goDetail={e => goDetail('design',e)}/>
                    </IndexBox>
                    <IndexBox title="资讯中心"  more={ ()=>goList('info') } ext={ExtFour}>
                        <InfoBox data={data} goDetail={e => goDetail('info',e)}/>
                    </IndexBox>
                </div>
            }
        </div>
    );
}