import React from 'react';
import css from './industryRanking.module.less';

import echarts from 'echarts'

/** 行业排名 */
export class IndustryRanking extends React.Component{
    constructor(props) {
        super(props);
        const {data=[]} = props;
        this.state = {
            data
        };
    }
    componentDidMount(){
        this.loadMap();
    }

     loadMap =()=> {
        let xData = [];
        let data = [];
            this.state.data.map((item)=>{
                xData.push(item.name);
                data.push(item.value)

         });

      const  option = {
          title: {
              text: '热门行业排名',
              subtext: '注释：图中数值为热门行业发布的项目总数',
              left: 'left'
          },
             tooltip: {
                 axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                     type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                 },
                 formatter: function(params) {
                     const {name='',value=''} = params;
                     return name + '： ' + value;
                 }
             },
              toolbox: {
                  show: true,
                  feature: {
                      saveAsImage: {
                          pixelRatio: 4
                      }
                  }
              },
             grid: {
                 left: '3%',
                 right: '4%',
                 top: '30%',
                 bottom: '2%',
                 containLabel: true
             },
             xAxis: [
                 {
                     type: 'category',
                     "boundaryGap": true,
                     data: xData,
                     axisTick: {
                         "show":false,
                         alignWithLabel: true
                     },
                     "axisLine":{       //y轴
                         "show":false

                     },
                     "splitLine": {     //网格线
                         "show": false
                     },
                     axisLabel: {
                         show: true,
                         textStyle: {
                             fontSize : 14      //更改坐标轴文字大小
                         }
                     },
                 }
             ],

             yAxis: [
                 {
                     show : false,
                     type: 'value'
                 }
             ],
             series: [
                 {
                     name: '热门行业',
                     type: 'bar',
                     barWidth: '58',
                     data: data,
                     label: {
                         show: true,
                         position: 'top',
                         fontSize: 20
                     },
                     itemStyle: {
                         // barBorderRadius: [10,10,0,0],
                         barBorderRadius:30,
                         normal: {
                             color: (params) => {
                                 let colors = ['#4150d8', '#28bf7e', '#ed7c2f', '#f2a93b', '#f9cf36', '#4a5bdc', '#4cd698', '#f4914e', '#fcb75b', '#ffe180', '#b6c2ff', '#96edc1']
                                 return colors[params.dataIndex]
                             }
                         }
                     },
                 }
             ]
         };
        var myChart = echarts.init(document.getElementById('industryRanking'));

        myChart.setOption(option);
    }

    render(){
        return (
            <div className={css.list} >
                <div id={'industryRanking'}  style={{ width: '100%', height: 311 }} />
            </div>
        );
    }

}