import React, {useState} from 'react';
import css from './list.module.less';
import {Steps} from "../../../components/steps";


export const List =(props)=>{
    const { data =[] ,count=0,toView} = props;
    const goDetail = (opt)=>{
        toView(opt);

    }
    return (
        <div >

            <div className={css.listTitle}>
                <div className={css.title}>
                    最新项目
                </div>
                <div className={css.ext}>
                    共有 <span>{count}</span> 条数据
                </div>
            </div>
            <div className={ css.list }>
                {
                    ( data ).map((opt,index)=>
                        <div className={ css.item } key={ index }  >
                            <div className={ css.title } onClick={()=>goDetail(opt)}>
                                {opt.name}
                            </div>
                            <div className={css.subtitle}>
                                <span>林地地区：{opt.pname}</span>
                                <span>所属行业：{ opt.industry_name }</span>
                                <span className={css.time}>发起时间：{ opt.createtime }</span>
                            </div>
                            <Steps current={opt.stage_id} style={{width: '550px'}}/>
                            <div className={css.operation}>
                                <div className={css.money}>{opt.budget} <span className={css.unit}>亿</span></div>
                                <div className={css.btn} onClick={()=>goDetail(opt)}>查看详情</div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>

    );
}